var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageHolder',{attrs:{"breadCombsText":("Home - Expedições - " + (_vm.isNewExpedition ? 'Nova' : 'Editar'))}},[_c('v-card',{staticClass:"flex-grow-1"},[_c('v-col',[_c('v-row',{staticClass:"pl-8 pr-8 pa-4"},[_c('h3',[_vm._v(_vm._s(_vm.isNewExpedition ? "Nova" : "Editar")+" Expedição")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"outlined":"","color":"#8C8C8C"},on:{"click":function($event){return _vm.handleCancelButtom()}}},[_vm._v("CANCELAR")]),_c('v-btn',{staticClass:"ml-4",attrs:{"dark":"","color":"#303030"},on:{"click":function($event){return _vm.clearApiErrorsAndSubmitExpedition()}}},[_vm._v(" SALVAR ")])],1),_c('v-divider'),_c('v-row',{staticClass:"pl-8 pr-8 pa-4"},[_c('v-form',{ref:"form",staticClass:"flex-grow-1",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_c('v-text-field',{attrs:{"label":"Nome*","required":"","rules":[_vm.rules.required, _vm.rules.fieldErrors('nome')],"hint":"ex. Marcos Vinicius e Hugo Transportes","persistent-hint":""},model:{value:(_vm.expedition.nome),callback:function ($$v) {_vm.$set(_vm.expedition, "nome", $$v)},expression:"expedition.nome"}})],1),_c('v-col',{attrs:{"lg":"3"}},[_c('v-select',{attrs:{"items":_vm.states,"item-text":"state","item-value":"abbr","label":"Estado*","rules":[_vm.rules.required, _vm.rules.fieldErrors('endereco.uf')],"single-line":""},model:{value:(_vm.expedition.endereco.uf),callback:function ($$v) {_vm.$set(_vm.expedition.endereco, "uf", $$v)},expression:"expedition.endereco.uf"}})],1),_c('v-col',{attrs:{"lg":"3"}},[_c('v-text-field',{attrs:{"label":"Missão (Cidade)*","required":"","rules":[
                  _vm.rules.required,
                  _vm.rules.fieldErrors('endereco.missao')
                ],"hint":"ex. Fortaleza","persistent-hint":""},model:{value:(_vm.expedition.endereco.missao),callback:function ($$v) {_vm.$set(_vm.expedition.endereco, "missao", $$v)},expression:"expedition.endereco.missao"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_c('v-text-field',{attrs:{"label":"Bairro*","required":"","rules":[
                  _vm.rules.required,
                  _vm.rules.fieldErrors('endereco.bairro')
                ],"hint":"ex. Antônio Bezerra","persistent-hint":""},model:{value:(_vm.expedition.endereco.bairro),callback:function ($$v) {_vm.$set(_vm.expedition.endereco, "bairro", $$v)},expression:"expedition.endereco.bairro"}})],1),_c('v-col',{attrs:{"lg":"4"}},[_c('v-text-field',{attrs:{"label":"Rua*","required":"","rules":[_vm.rules.required, _vm.rules.fieldErrors('endereco.rua')],"hint":"ex. Liz Sophia Teixeira","persistent-hint":""},model:{value:(_vm.expedition.endereco.rua),callback:function ($$v) {_vm.$set(_vm.expedition.endereco, "rua", $$v)},expression:"expedition.endereco.rua"}})],1),_c('v-col',{attrs:{"lg":"2"}},[_c('v-text-field',{attrs:{"label":"Número*","required":"","rules":[
                  _vm.rules.required,
                  _vm.rules.fieldErrors('endereco.numero')
                ],"hint":"ex. 16","persistent-hint":""},model:{value:(_vm.expedition.endereco.numero),callback:function ($$v) {_vm.$set(_vm.expedition.endereco, "numero", $$v)},expression:"expedition.endereco.numero"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('v-text-field',{attrs:{"label":"Complemento","rules":[_vm.rules.fieldErrors('endereco.complemento')],"hint":"ex. Bloco A","persistent-hint":""},model:{value:(_vm.expedition.endereco.complemento),callback:function ($$v) {_vm.$set(_vm.expedition.endereco, "complemento", $$v)},expression:"expedition.endereco.complemento"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_c('v-text-field',{attrs:{"label":"Pessoa para contato*","required":"","rules":[_vm.rules.required, _vm.rules.fieldErrors('contato.nome')],"hint":"ex. Maria Clara Záfon","persistent-hint":""},model:{value:(_vm.expedition.contato.nome),callback:function ($$v) {_vm.$set(_vm.expedition.contato, "nome", $$v)},expression:"expedition.contato.nome"}})],1),_c('v-col',{attrs:{"lg":"3"}},[_c('v-text-field',{attrs:{"label":"Contato 1*","required":"","rules":[
                  _vm.rules.required,
                  _vm.rules.fieldErrors('contato.contato1')
                ],"hint":"ex. (85)1234-7894","persistent-hint":""},model:{value:(_vm.expedition.contato.contato1),callback:function ($$v) {_vm.$set(_vm.expedition.contato, "contato1", $$v)},expression:"expedition.contato.contato1"}})],1),_c('v-col',{attrs:{"lg":"3"}},[_c('v-text-field',{attrs:{"label":"Contato 2","rules":[_vm.rules.fieldErrors('contato.contato2')],"hint":"ex. (85)1234-7894","persistent-hint":""},model:{value:(_vm.expedition.contato.contato2),callback:function ($$v) {_vm.$set(_vm.expedition.contato, "contato2", $$v)},expression:"expedition.contato.contato2"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_c('v-text-field',{attrs:{"label":"Email","hint":"ex. email@email.com","rules":[_vm.rules.validEmail, _vm.rules.fieldErrors('email')],"persistent-hint":""},model:{value:(_vm.expedition.email),callback:function ($$v) {_vm.$set(_vm.expedition, "email", $$v)},expression:"expedition.email"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
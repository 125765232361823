<template>
  <v-layout child-flex>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="pageSize"
      :server-items-length="itemsLength"
      @update:page="updatePage"
      @update:options="updateOptions"
      :loading="isHavingProgress === true"
      loading-text="Carregando... Por favor aguarde"
      class="elevation-1 mt-8"
      fill-width
      no-data-text="Sem dados disponíveis"
      no-results-text="Nenhuma entrada encontrada"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="clickOnEditItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="clickOnDeleteItem(item)"> mdi-delete </v-icon>
      </template>

      <template
        v-if="primaryButtomLabel && clickOnPrimaryButtom"
        v-slot:body.append
      >
        <v-btn
          color="#303030"
          class="ma-4 ml-8"
          dark
          @click="clickOnPrimaryButtom"
        >
          {{ primaryButtomLabel }}
        </v-btn>
      </template>
    </v-data-table>
  </v-layout>
</template>

<script>
export default {
  name: "RegistersTable",
  props: {
    headers: Array,
    items: Array,
    clickOnEditItem: Function,
    clickOnDeleteItem: Function,
    clickOnPrimaryButtom: Function,
    updatePage: Function,
    updateOptions: Function,
    primaryButtomLabel: String,
    pageSize: Number,
    itemsLength: Number
  },
  computed: {
    headersWithActions() {
      if (!this.clickOnEditItem && !this.clickOnDeleteItem) {
        return this.headers;
      }
      return this.headers.concat({
        text: "Ações",
        value: "actions",
        sortable: false
      });
    },
    isHavingProgress() {
      return this.$store.getters.isHavingProgress;
    }
  }
};
</script>

<style>
table {
  white-space: nowrap;
}
</style>

<template>
  <v-menu
    v-model="isMenuOpen"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formatedDate"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      elevation="2"
      v-model="innerValue"
      :allowed-dates="allowedDates"
      @input="onInput()"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: ["label", "value", "allowedDates"],
  data: () => ({
    isMenuOpen: false,
    innerValue: ""
  }),
  computed: {
    formatedDate() {
      if (this.value == "") {
        return null;
      }

      const [year, month, day] = this.innerValue.split("-");
      return `${day}/${month}/${year}`;
    }
  },
  watch: {
    value(newValue) {
      this.innerValue = newValue;
    }
  },
  created() {
    this.innerValue = this.value;
  },
  methods: {
    onInput() {
      this.isMenuOpen = false;
      this.$emit("input", this.innerValue);
    }
  }
};
</script>

<style></style>

<template>
  <page-holder
    :breadCombsText="`Home - Usuários - ${isNewUser ? 'Novo' : 'Editar'}`"
  >
    <v-card class="flex-grow-1">
      <v-col>
        <v-row class="pl-8 pr-8 pa-4">
          <h3>{{ isNewUser ? "Novo" : "Editar" }} Usuário</h3>
          <v-btn
            class="ml-auto"
            outlined
            color="#8C8C8C"
            @click="handleCancelButtom()"
            >CANCELAR</v-btn
          >
          <v-btn dark color="#303030" class="ml-4" @click="handleSaveButtom()">
            SALVAR
          </v-btn>
        </v-row>
        <v-divider />
        <v-row class="pl-8 pr-8 pa-4">
          <v-form ref="form" class="flex-grow-1" v-model="isFormValid">
            <v-row>
              <v-col lg="6">
                <v-text-field
                  label="Nome*"
                  required
                  v-model="user.nome"
                  :rules="[rules.required, rules.fieldErrors('nome')]"
                  hint="ex.
                Liz Sophia Teixeira"
                  persistent-hint
                />
              </v-col>
              <v-col lg="6">
                <v-text-field
                  label="Email*"
                  required
                  v-model="user.email"
                  :rules="[
                    rules.required,
                    rules.validEmail,
                    rules.fieldErrors('email')
                  ]"
                  hint="ex.
                lizsophiateixeira_@obrativaengenharia.com.br"
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="3">
                <v-select
                  :items="states"
                  item-text="state"
                  item-value="abbr"
                  label="Estado*"
                  v-model="user.endereco.uf"
                  :rules="[rules.required, rules.fieldErrors('endereco.uf')]"
                  single-line
                />
              </v-col>
              <v-col lg="3">
                <v-text-field
                  label="Missão (Cidade)*"
                  required
                  v-model="user.endereco.missao"
                  :rules="[
                    rules.required,
                    rules.fieldErrors('endereco.missao')
                  ]"
                  hint="ex. Fortaleza"
                  persistent-hint
                />
              </v-col>
              <v-col lg="3">
                <v-text-field
                  label="Contato 1*"
                  required
                  v-model="user.contato.contato1"
                  :rules="[
                    rules.required,
                    rules.fieldErrors('contato.contato1'),
                    rules.minLengthRequired(10)
                  ]"
                  hint="ex. (85)1234-7894"
                  persistent-hint
                />
              </v-col>
              <v-col lg="3">
                <v-text-field
                  label="Contato 2"
                  v-model="user.contato.contato2"
                  :rules="[
                    rules.fieldErrors('contato.contato2'),
                    rules.minLengthRequired(10)
                  ]"
                  hint="ex. (85)1234-7894"
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="3">
                <v-text-field
                  label="Login*"
                  v-model="user.login"
                  required
                  :readonly="!isNewUser"
                  :rules="[rules.required, rules.fieldErrors('login')]"
                  :hint="isNewUser ? 'ex. maria_clara' : ''"
                  persistent-hint
                />
              </v-col>
              <v-col lg="3">
                <v-text-field
                  :label="isNewUser ? 'Senha*' : 'Senha'"
                  v-model="user.senha"
                  required
                  :rules="[
                    rules.ifCreateIsRequired,
                    rules.fieldErrors('senha'),
                    rules.minLengthRequired(4)
                  ]"
                  persistent-hint
                  type="password"
                />
              </v-col>
              <v-col lg="3">
                <v-text-field
                  :label="
                    isNewUser ? 'Confirmação de senha*' : 'Confirmação de senha'
                  "
                  v-model="user.confirmacaoSenha"
                  required
                  :rules="[
                    rules.ifCreateIsRequired,
                    rules.matchPasswordFieldValue,
                    rules.fieldErrors('confirmacaoSenha')
                  ]"
                  persistent-hint
                  type="password"
                />
              </v-col>
              <v-col lg="3">
                <v-select
                  v-show="user.perfil === userTypes.operator"
                  :items="expeditions"
                  item-text="nome"
                  item-value="id"
                  label="Expedição*"
                  v-model="user.idRegistro"
                  :readonly="!isNewUser"
                  :rules="[
                    rules.ifIsOperatorIsRequired,
                    rules.fieldErrors('idRegistro')
                  ]"
                  single-line
                />
              </v-col>
            </v-row>
            <v-row v-if="isAdmin">
              <v-col>
                <p class="mb-0">Acesso do Perfil*</p>
                <v-radio-group
                  class="mt-1"
                  mandatory
                  row
                  v-model="user.perfil"
                  :readonly="!isNewUser"
                  :rules="[rules.required, rules.fieldErrors('perfil')]"
                >
                  <v-radio
                    label="Operador expedição"
                    :value="userTypes.operator"
                  />
                  <v-radio
                    label="Administrador"
                    :value="userTypes.admin"
                    @click="removeExpedition()"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-form>
        </v-row>
      </v-col>
    </v-card>
  </page-holder>
</template>

<script>
import immutable from "immutable";
import {
  required,
  requiredIfConditionMatch,
  matchPasswordFieldValue,
  fieldErrors,
  minLengthRequired
} from "../../../utils/formFieldRules";
import states from "../../../enums/states";
import { userTypes } from "../utils";
export default {
  mounted() {
    if (this.isAdmin) {
      this.$store.dispatch("requestExpeditions", { page: 0, pageSize: -1 });
    }
  },
  data: function() {
    const isNewUser = !this.$route.params.id;
    const user = immutable
      .mergeDeep(
        immutable.Map({
          nome: null,
          email: null,
          perfil: userTypes.operator,
          endereco: {
            estado: null,
            missao: null,
            bairro: "CAMPO_NÃO_EXISTENTE"
          },
          contato: { contato1: null, contato2: null },
          login: null,
          senha: null,
          confirmacaoSenha: null,
          idRegistro: null
        }),
        immutable.Map(this.$store.getters.getUser(this.$route.params.id) ?? {})
      )
      .toJS();
    if (user.expedicao) {
      user.idRegistro = user.expedicao.id;
    }

    const userType = this.$store.state.authStore.userType;
    const isAdmin = userType === userTypes.admin;
    return {
      user,
      isFormValid: false,
      isAdmin,
      userTypes,
      isNewUser,
      states: Object.keys(states)
        .sort((a, b) => a > b)
        .map(s => ({ state: states[s], abbr: s }))
    };
  },
  methods: {
    handleSaveButtom() {
      this.$store.commit("clearApiErrors");
      setTimeout(() => {
        this.ifFormValidSubmitUser();
      }, 200);
    },
    ifFormValidSubmitUser() {
      if (this.isFormValid) {
        this.user.contato.nome = this.user.nome;
        this.$store.dispatch(
          "submitUser",
          immutable
            .Map(this.user)
            .set("perfil", "ROLE_" + this.user.perfil)
            .toJS()
        );
      } else {
        this.$refs.form.validate();
      }
    },
    handleCancelButtom() {
      this.$store.dispatch("displayAlert", {
        message: "Operação cancelada!",
        type: "warning"
      });
      this.$router.go(-1);
    },
    removeExpedition() {
      this.user.idRegistro = undefined;
    }
  },
  computed: {
    rules() {
      const apiErrors = this.$store.getters.getApiErrors;
      const ifCreateIsRequired = requiredIfConditionMatch(this.isNewUser);
      const isCreatingOperator = this.user.accessType == "operator";
      const ifIsOperatorIsRequired = requiredIfConditionMatch(
        isCreatingOperator
      );
      return {
        required,
        ifCreateIsRequired,
        ifIsOperatorIsRequired,
        validEmail: v => /.+@.+/.test(v) || "E-mail inválido",
        matchPasswordFieldValue: matchPasswordFieldValue(this.user.senha),
        fieldErrors: fieldErrors(apiErrors),
        minLengthRequired
      };
    },
    expeditions() {
      return this.$store.state.expeditionsStore.expeditions;
    },
    apiErrors() {
      return this.$store.state.usersStore.apiErrors;
    }
  }
};
</script>

<style></style>

import { createRequestUrl, createRequestInit } from "../../service/service";
import router from "../../router";
import { dashboardRoutesPaths } from "../dashboard/routes";
import { authRoutesPaths } from "./routes";
import { clearState } from "../../utils/vuex";

const initialState = {
  userType: null,
  failedToLogin: false,
  failedToSendEmail: false,
  failedToChangePassword: false,
  isHavingProgress: false,
  token: null
};

export const authStore = {
  state: () => ({ ...initialState }),
  mutations: {
    setUserData(state, loginData) {
      state.userType = loginData.perfil.replace("ROLE_", "");
      state.username = loginData.nome;
      state.token = loginData.token;
    },
    setFailedToLogin(state, value) {
      state.failedToLogin = value;
    },
    setFailedToSendEmail(state, value) {
      state.failedToSendEmail = value;
    },
    setFailedToChangePassword(state, value) {
      state.failedToChangePassword = value;
    },
    setIsHavingProgress(state, value) {
      state.isHavingProgress = value;
    },
    clearAuthStore(state) {
      clearState(state, initialState);
    }
  },
  getters: {
    getToken: state => state.token,
    getUserType: state => state.userType
  },
  actions: {
    async login({ commit }, { username, password }) {
      commit("setIsHavingProgress", true);

      const response = await fetch(
        createRequestUrl("/login"),
        createRequestInit({
          method: "POST",
          body: { login: username, senha: password }
        })
      );

      commit("setIsHavingProgress", false);

      const responseBody = await response.json();

      if (response.ok) {
        commit("setUserData", responseBody);
        sessionStorage.setItem("access_info", JSON.stringify(responseBody));
        router.push(dashboardRoutesPaths.dashboard);
        commit("setFailedToLogin", false);
        return;
      } else {
        commit("setFailedToLogin", true);
      }
    },
    async forgotPassword({ commit, dispatch }, email) {
      commit("setIsHavingProgress", true);

      const response = await fetch(
        createRequestUrl("/senha/esqueci"),
        createRequestInit({
          method: "POST",
          body: email
        })
      );

      commit("setIsHavingProgress", false);

      if (response.ok) {
        router.push(authRoutesPaths.newPassword);
        dispatch("displayAlert", {
          message: "Um e-mail foi enviado para o endereço que você forneceu",
          type: "success"
        });
        commit("setFailedToSendEmail", false);
      } else {
        commit("setFailedToSendEmail", true);
      }
    },
    async newPassword({ commit, dispatch }, { token, password }) {
      commit("setIsHavingProgress", true);

      const response = await fetch(
        createRequestUrl("/senha/alterar"),
        createRequestInit({
          method: "POST",
          body: { token: token, senha: password, confirmacaoSenha: password }
        })
      );

      commit("setIsHavingProgress", false);

      if (response.ok) {
        router.push(authRoutesPaths.login);
        dispatch("displayAlert", {
          message: "Senha alterada com sucesso",
          type: "success"
        });
        commit("setFailedToChangePassword", false);
      } else {
        commit("setFailedToChangePassword", true);
      }
    },
    logout({ commit }) {
      sessionStorage.removeItem("access_info");
      commit("clearExpeditionsStore");
      commit("clearOrderStore");
      commit("clearUserStore");
      commit("clearDeliverymanStore");
      commit("clearDashboardStore");
      commit("clearAuthStore");
      commit("clearCarrierStore");
    }
  }
};

import DeliverymanPage from "./views/DeliverymanPage";
import DeliverymenFormPage from "./views/DeliverymenFormPage";

export const deliverymenRoutesPaths = {
  deliverymen: "/deliverymen",
  createDeliveryman: "/deliverymen/create",
  editDeliveryman: "/deliverymen/:id"
};

export const deliverymenRoutes = [
  {
    path: deliverymenRoutesPaths.deliverymen,
    name: "Deliverymen",
    component: DeliverymanPage
  },
  {
    path: deliverymenRoutesPaths.createDeliveryman,
    name: "Create Deliveryman",
    component: DeliverymenFormPage
  },
  {
    path: deliverymenRoutesPaths.editDeliveryman,
    name: "Edit Deliveryman",
    component: DeliverymenFormPage
  }
];

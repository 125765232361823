import CarriesPage from "./views/CarriesPage";
import CarrierFormPage from "./views/CarrierFormPage";

export const carriersRoutesPaths = {
  carriers: "/carriers",
  createCarrier: "/carriers/create",
  editCarrier: "/carriers/:id"
};

export const carriersRoutes = [
  {
    path: carriersRoutesPaths.carriers,
    name: "Carriers",
    component: CarriesPage
  },
  {
    path: carriersRoutesPaths.createCarrier,
    name: "Create Carrier",
    component: CarrierFormPage
  },
  {
    path: carriersRoutesPaths.editCarrier,
    name: "Edit Carrier",
    component: CarrierFormPage
  }
];

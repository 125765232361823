<template>
  <PageHolder breadCombsText="Home - Usuários" hideLoading>
    <v-row no-gutters>
      <v-col lg="6">
        <h3>Usuários</h3>
      </v-col>
      <v-col lg="6">
        <v-text-field
          label="Pesquisar Usuário"
          v-model="nameFilter"
          hint="ex. Jaime Bezerra"
          persistent-hint
        />
      </v-col>
    </v-row>
    <v-row>
      <RegistersTable
        :clickOnPrimaryButtom="navigateToCreateUser"
        :clickOnDeleteItem="deleteItem"
        :clickOnEditItem="editItem"
        :headers="headers"
        :items="users"
        :pageSize="pageSize"
        :itemsLength="usersCount"
        :updatePage="requestItems"
        :updateOptions="updateTableOptions"
        primaryButtomLabel="Novo Usuário"
      />
    </v-row>

    <ConfirmationDialog
      ref="dialog"
      question="Deseja mesmo excluir esse usuário?"
      confirmButtomLabel="Excluir"
      :handleConfirm="confirmDeleteItem"
      cancelMessage="Exclusão cancelada!"
    />
  </PageHolder>
</template>

<script>
import { usersRoutesPaths } from "../routes";
import { formatUsersProfile } from "../utils";
export default {
  mounted() {
    this.requestItems(1);
  },
  computed: {
    users() {
      const users = this.$store.state.usersStore.users;
      const userWithFormattedUserProfile = users.map(u =>
        formatUsersProfile(u)
      );
      return userWithFormattedUserProfile;
    },
    usersCount() {
      return this.$store.state.usersStore.usersCount;
    }
  },
  data: () => ({
    nameFilter: null,
    headers: [
      {
        text: "Nome",
        value: "nome"
      },
      {
        text: "Estado",
        value: "endereco.uf"
      },
      {
        text: "Missão (Cidade)",
        value: "endereco.missao"
      },
      {
        text: "Expedição",
        value: "expedicao.nome"
      },
      {
        text: "Perfil",
        value: "perfil"
      },
      { text: "Ações", value: "actions", sortable: false }
    ],
    pageSize: 10
  }),
  methods: {
    editItem(item) {
      this.$router.push(usersRoutesPaths.editUser.replace(":id", item.id));
    },
    deleteItem(item) {
      this.$store.dispatch("deleteUser", item);
    },
    navigateToCreateUser: function() {
      this.$router.push(usersRoutesPaths.createUser);
    },
    confirmDeleteItem() {
      this.$store.commit("deleteUser", this.userBeingDeleted.id);
      this.$store.dispatch("displayAlert", {
        message: "O usuário não tem mais acesso ao sistema",
        type: "success"
      });
    },
    async requestItems(page) {
      if (this.nameFilter && this.nameFilter.length >= 3) {
        await this.$store.dispatch("requestUsers", {
          nameFilter: this.nameFilter
        });
        this.pageSize = -1;
      } else {
        this.$store.dispatch("requestUsers", {
          page: page - 1,
          pageSize: this.pageSize
        });
      }
    },
    updateTableOptions(options) {
      this.pageSize = options.itemsPerPage;
      this.requestItems(options.page);
    }
  },
  watch: {
    nameFilter() {
      if (this.nameFilter && this.nameFilter.length >= 3) {
        this.requestItems();
      }
    }
  }
};
</script>

<style></style>

import immutable from "immutable";
import { createRequestInit, createRequestUrl } from "./service";

export const serviceStore = {
  state: () => ({
    requestCount: 0,
    requests: {},
    apiErrors: []
  }),
  mutations: {
    incrementRequestCounter(state) {
      state.requestCount += 1;
    },
    storeResponse(state, { requestId, response }) {
      state.requests[requestId] = response;
    },
    deleteRequest(state, requestId) {
      const requestsEntries = Object.entries(state.requests);
      const requestsWithoutDeletedEntry = requestsEntries.filter(
        e => e[0] !== requestId.toString()
      );
      state.requests = Object.fromEntries(requestsWithoutDeletedEntry);
    },
    setApiErrors(state, errors) {
      state.apiErrors = errors;
    },
    clearApiErrors(state) {
      state.apiErrors = [];
    }
  },
  getters: {
    getRequestCount: state => state.requestCount,
    getRequests: state => state.requests,
    getApiErrors: state => state.apiErrors
  },
  actions: {
    async requestWithAuth(
      { commit, getters },
      { requestId, route, additionalRequestInit }
    ) {
      const token = getters.getToken;

      if (!token) {
        commit("navigateToLogin");
        return;
      }

      commit("setIsHavingProgress", true);

      const response = await fetch(
        createRequestUrl(route),
        createRequestInit(
          immutable.mergeDeep(
            {
              headers: { Authorization: "Bearer " + token }
            },
            additionalRequestInit || {}
          )
        )
      );

      const responseBody = await response.json();

      commit("setIsHavingProgress", false);

      if (responseBody.errors) {
        commit("setApiErrors", responseBody.errors);
      }

      commit("storeResponse", {
        requestId,
        response: {
          body: responseBody,
          ok: response.ok,
          status: response.status
        }
      });
    }
  }
};

export function getRequestIdAndIncrementCounter({ commit, getters }) {
  commit("incrementRequestCounter");
  return getters.getRequestCount;
}

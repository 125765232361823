import UsersPage from "./views/UsersPage.vue";
import UserFormPage from "./views/UserFormPage.vue";

export const usersRoutesPaths = {
  users: "/users",
  createUser: "/users/create",
  editUser: "/users/:id"
};

export const usersRoutes = [
  {
    path: usersRoutesPaths.users,
    name: "Users",
    component: UsersPage
  },
  {
    path: usersRoutesPaths.createUser,
    name: "Create User",
    component: UserFormPage
  },
  {
    path: usersRoutesPaths.editUser,
    name: "Edit User",
    component: UserFormPage
  }
];

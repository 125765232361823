<template>
  <PageHolder
    :breadCombsText="
      `Home - Transportadora - ${isNewCarrier ? 'Nova' : 'Editar'}`
    "
  >
    <v-card class="flex-grow-1">
      <v-col>
        <v-row class="pl-8 pr-8 pa-4">
          <h3>{{ isNewCarrier ? "Nova" : "Editar" }} Transportadora</h3>
          <v-btn
            class="ml-auto"
            outlined
            color="#8C8C8C"
            @click="handleCancelButtom()"
            >CANCELAR</v-btn
          >
          <v-btn dark color="#303030" class="ml-4" @click="handleSaveButtom()">
            SALVAR
          </v-btn>
        </v-row>
        <v-divider />
        <v-row class="pl-8 pr-8 pa-4">
          <v-form ref="form" class="flex-grow-1" v-model="isFormValid">
            <v-row>
              <v-col lg="9">
                <v-text-field
                  label="Nome*"
                  v-model="carrier.nome"
                  required
                  :rules="[rules.required, rules.fieldErrors('nome')]"
                  hint="ex. Marcos Vinicius e Hugo Transportes"
                  persistent-hint
                />
              </v-col>
              <v-col lg="3">
                <v-text-field
                  label="CPF / CNPJ*"
                  v-model="carrier.cpfOuCnpj"
                  required
                  :rules="[
                    rules.required,
                    rules.validateCpfCnpj,
                    rules.fieldErrors('CpfOuCnpj')
                  ]"
                  hint="ex. 608.412.433-23"
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="3">
                <v-select
                  :items="states"
                  item-text="state"
                  item-value="abbr"
                  label="Estado*"
                  v-model="carrier.endereco.uf"
                  :rules="[rules.required, rules.fieldErrors('endereco.uf')]"
                  single-line
                />
              </v-col>
              <v-col lg="3">
                <v-text-field
                  label="Missão (Cidade)*"
                  v-model="carrier.endereco.missao"
                  required
                  :rules="[
                    rules.required,
                    rules.fieldErrors('endereco.missao')
                  ]"
                  hint="ex. Fortaleza"
                  persistent-hint
                />
              </v-col>
              <v-col lg="4">
                <v-text-field
                  label="Rua"
                  v-model="carrier.endereco.rua"
                  hint="ex. Liz Sophia Teixeira"
                  persistent-hint
                />
              </v-col>
              <v-col lg="2">
                <v-text-field
                  label="Número"
                  v-model="carrier.endereco.numero"
                  hint="ex. 16"
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="3">
                <v-text-field
                  label="Bairro"
                  v-model="carrier.endereco.bairro"
                  hint="ex. Antônio Bezerra"
                />
              </v-col>
              <v-col lg="3">
                <v-text-field
                  label="Complemento"
                  v-model="carrier.endereco.complemento"
                  hint="ex. Bloco A"
                  persistent-hint
                />
              </v-col>
              <v-col lg="6">
                <v-select
                  :items="expeditions"
                  item-text="nome"
                  item-value="id"
                  label="Expedição*"
                  v-model="carrier.idExpedicao"
                  :rules="[rules.required, rules.fieldErrors('idExpedicao')]"
                  single-line
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="4">
                <v-text-field
                  label="Login usuário 1*"
                  required
                  v-model="carrier.usuario1.login"
                  :rules="[rules.required, rules.fieldErrors('usuario1.login')]"
                  hint="ex. maria_clara"
                  persistent-hint
                />
              </v-col>
              <v-col lg="4">
                <v-text-field
                  label="Senha usuário 1*"
                  type="password"
                  v-model="carrier.usuario1.senha"
                  :rules="[
                    rules.requiredIfCreate,
                    rules.minLengthRequired(4),
                    rules.fieldErrors('usuario1.senha')
                  ]"
                  persistent-hint
                />
              </v-col>
              <v-col lg="4">
                <v-text-field
                  label="Confirmar senha usuário 1*"
                  type="password"
                  v-model="carrier.usuario1.confirmacaoSenha"
                  :rules="[
                    rules.matchUserPassword1,
                    rules.requiredIfCreate,
                    rules.fieldErrors('usuario1.confirmacaoSenha')
                  ]"
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="4">
                <v-text-field
                  label="Login usuário 2"
                  v-model="carrier.usuario2.login"
                  hint="ex. maria_clara"
                  persistent-hint
                />
              </v-col>
              <v-col lg="4">
                <v-text-field
                  label="Senha usuário 2"
                  type="password"
                  v-model="carrier.usuario2.senha"
                  :rules="[
                    rules.minLengthRequired(4),
                    rules.fieldErrors('usuario2.senha')
                  ]"
                  persistent-hint
                />
              </v-col>
              <v-col lg="4">
                <v-text-field
                  label="Confirmar senha usuário 2"
                  type="password"
                  v-model="carrier.usuario2.confirmacaoSenha"
                  :rules="[
                    rules.matchUserPassword2,
                    rules.fieldErrors('usuario2.confirmacaoSenha')
                  ]"
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="6">
                <v-text-field
                  label="Pessoa para contato*"
                  required
                  v-model="carrier.contato.nome"
                  :rules="[rules.required, rules.fieldErrors('contato.nome')]"
                  hint="ex. Maria Clara Záfon"
                  persistent-hint
                />
              </v-col>
              <v-col lg="3">
                <v-text-field
                  label="Contato 1*"
                  required
                  v-model="carrier.contato.contato1"
                  :rules="[
                    rules.required,
                    rules.minLengthRequired(10),
                    rules.fieldErrors('contato.contato1')
                  ]"
                  hint="ex. (85)1234-7894"
                  persistent-hint
                />
              </v-col>
              <v-col lg="3">
                <v-text-field
                  label="Contato 2"
                  v-model="carrier.contato.contato2"
                  :rules="[
                    rules.minLengthRequired(10),
                    rules.fieldErrors('contato.contato2')
                  ]"
                  hint="ex. (85)1234-7894"
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="6">
                <v-text-field
                  label="Email*"
                  hint="ex. email@email.com"
                  v-model="carrier.email"
                  :rules="[
                    rules.validEmail,
                    rules.required,
                    rules.fieldErrors('email')
                  ]"
                  persistent-hint
                />
              </v-col>
            </v-row>
          </v-form>
        </v-row>
      </v-col>
    </v-card>
  </PageHolder>
</template>

<script>
import {
  required,
  matchPasswordFieldValue,
  requiredIfConditionMatch,
  minLengthRequired,
  validEmail,
  fieldErrors,
  validateCpfCnpj
} from "../../../utils/formFieldRules";
import states from "../../../enums/states";
export default {
  mounted() {
    this.$store.dispatch("requestExpeditions", { page: 0, pageSize: -1 });
  },
  data: function() {
    const isNewCarrier = !this.$route.params.id;
    const carrier = Object.assign(
      {
        nome: null,
        email: null,
        cpfOuCnpj: null,
        idExpedicao: null,
        contato: {
          nome: null,
          contato1: null,
          contato2: null
        },
        endereco: {
          uf: null,
          bairro: null,
          missao: null,
          rua: null,
          numero: null,
          complemento: null
        },
        usuario1: {
          login: null,
          confirmacaoSenha: null,
          senha: null
        },
        usuario2: {
          login: null,
          confirmacaoSenha: null,
          senha: null
        }
      },
      this.$store.getters.getCarrier(this.$route.params.id) ?? {}
    );
    if (carrier.expedicao) {
      carrier.idExpedicao = carrier.expedicao.id;
    }
    return {
      carrier,
      isNewCarrier,
      isFormValid: false,
      states: Object.keys(states)
        .sort((a, b) => a > b)
        .map(s => ({ abbr: s, state: states[s] }))
    };
  },
  computed: {
    rules() {
      const apiErrors = this.$store.getters.getApiErrors;
      return {
        required,
        requiredIfCreate: requiredIfConditionMatch(this.isNewCarrier),
        matchUserPassword1: matchPasswordFieldValue(
          this.carrier.usuario1.senha
        ),
        matchUserPassword2: matchPasswordFieldValue(
          this.carrier.usuario2.senha
        ),
        minLengthRequired,
        validEmail,
        validateCpfCnpj,
        fieldErrors: fieldErrors(apiErrors)
      };
    },
    expeditions() {
      return this.$store.state.expeditionsStore.expeditions;
    }
  },
  methods: {
    handleSaveButtom() {
      this.$store.commit("clearApiErrors");
      setTimeout(() => {
        this.submit();
      }, 200);
    },
    submit() {
      if (this.isFormValid) {
        this.carrier.cpfOuCnpj = this.carrier.cpfOuCnpj
          .replace(".", "")
          .replace(".", "")
          .replace("-", "");
        this.$store.dispatch("submitCarrier", this.carrier);
      } else {
        this.$refs.form.validate();
        this.$store.dispatch("displayAlert", {
          message: "Formulário preenchido incorretamente!",
          type: "error"
        });
      }
    },
    handleCancelButtom() {
      this.$store.dispatch("displayAlert", {
        message: "Operação cancelada!",
        type: "warning"
      });
      this.$router.go(-1);
    }
  }
};
</script>

<style></style>

<template>
  <v-app>
    <v-main>
      <v-container fluid style="height: 100vh">
        <v-layout fill-height>
          <v-row align="center">
            <v-col offset-md="7" md="4">
              <v-card class="login_card">
                <v-card-text>
                  <v-container>
                    <v-row justify="space-around">
                      <v-col class="d-flex justify-center">
                        <v-img
                          src="../../../assets/logo_with_text2.png"
                          maxHeight="8rem"
                          contain
                        />
                      </v-col>
                    </v-row>

                    <v-card-subtitle class="text-center">
                      Insira o token que recebeu por email e sua nova senha
                    </v-card-subtitle>

                    <v-form ref="form">
                      <v-text-field
                        prepend-icon="mdi-shield-key"
                        label="Token"
                        v-model="token"
                      />

                      <v-text-field
                        prepend-icon="mdi-lock"
                        label="Senha"
                        type="password"
                        v-model="password"
                      />

                      <v-text-field
                        prepend-icon="mdi-lock"
                        label="Confirmar senha"
                        type="password"
                        v-model="passwordConfirmation"
                        v-on:keyup.enter="handleSendButton()"
                      />

                      <div class="d-flex justify-center">
                        <h4
                          v-if="password !== passwordConfirmation"
                          class="error-message"
                        >
                          As senhas não coincidem
                        </h4>
                        <h4
                          v-else-if="failedToChangePassword"
                          class="error-message"
                        >
                          Token incorreto!
                        </h4>
                      </div>

                      <v-container>
                        <v-row justify="space-around">
                          <v-col class="d-flex justify-center">
                            <v-btn
                              color="secondary"
                              large
                              depressed
                              @click="handleSendButton()"
                            >
                              <span class="primary--text"> Enviar </span>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-expand-x-transition>
                        <v-progress-linear
                          v-show="isHavingProgress"
                          class="mt-4"
                          indeterminate
                        />
                      </v-expand-x-transition>
                    </v-form>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    token: null,
    password: null,
    passwordConfirmation: null
  }),
  methods: {
    handleSendButton() {
      if (this.password == this.passwordConfirmation) {
        this.$store.dispatch("newPassword", {
          token: this.token,
          password: this.passwordConfirmation
        });
      }
    }
  },
  computed: {
    isHavingProgress() {
      return this.$store.state.authStore.isHavingProgress;
    },
    failedToChangePassword() {
      return this.$store.state.authStore.failedToChangePassword;
    }
  }
};
</script>

<style>
.v-main__wrap {
  background: url("../../../assets/login-page_background.jpeg");
  background-size: 100%;
}
.error-message {
  color: #d50000;
}
</style>

<template>
  <v-dialog v-model="isVisible" width="900">
    <v-card>
      <v-card-title>
        <v-tabs v-model="tab" grow>
          <v-tab key="generalData"> Dados Gerais </v-tab>
          <v-tab key="products"> Produtos </v-tab>
        </v-tabs>
      </v-card-title>

      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item key="generalData">
            <v-row>
              <v-col lg="6">
                <p>
                  <b> ID do Pedido </b> <br />
                  {{ order.id }}
                </p>
                <p>
                  <b> Nome do Cliente </b> <br />
                  {{ order.nomeCliente }}
                </p>
                <p>
                  <b> Entrada na Expedição </b> <br />
                  {{ order.dtCadastro }}
                </p>
                <p>
                  <b> Transportadora </b> <br />
                  {{ order.nomeTransportadora }}
                </p>
                <p>
                  <b> Valor do transporte </b> <br />
                  {{ order.precoTransporte }}
                </p>
              </v-col>
              <v-col lg="6">
                <p>
                  <b> Cidade Destino </b> <br />
                  {{ order.nomeCidadeCliente }}
                </p>
                <p>
                  <b> Bairro Destino </b> <br />
                  {{ order.bairroClienteEnd }}
                </p>
                <p>
                  <b> Logradouro Destino </b> <br />
                  {{
                    order.nomeLogradouroCliente + ", " + order.numeroClientEnd
                  }}
                </p>
                <p>
                  <b> Agente </b> <br />
                  {{ order.nomeAgente }}
                </p>
                <p v-if="!isCarrier">
                  <b> Preço </b> <br />
                  {{ order.valorTotal }}
                </p>
              </v-col>
            </v-row>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col lg="6">
                <p>
                  <b> Status </b> <br />
                  {{ order.ultimoStatus }}
                </p>
                <p v-if="note">
                  <b> Observação </b> <br />
                  {{ note }}
                </p>
              </v-col>
              <v-col lg="6">
                <p v-if="justification">
                  <b> Justificativa </b> <br />
                  {{ justification }}
                </p>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item key="products">
            <v-data-table
              :headers="productHeaders"
              :items="order.itemPedidos"
              :items-per-page="5"
              class="elevation-1"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { userTypes } from "@/modules/users/utils";

export default {
  props: {
    order: Object,
    isDialogVisible: Boolean
  },
  data() {
    return {
      isVisible: false,
      tab: null
    };
  },
  computed: {
    isCarrier() {
      const userType = this.$store.state.authStore.userType;
      return userType == userTypes.carrier;
    },
    justification() {
      if (this.order.ultimoStatus === "Entrega frustrada") {
        return this.order.andamentoPedidos[
          this.order.andamentoPedidos.length - 1
        ].justificativa;
      } else {
        return null;
      }
    },
    note() {
      if (this.order.ultimoStatus === "Entrega frustrada") {
        return this.order.andamentoPedidos[
          this.order.andamentoPedidos.length - 1
        ].observacao;
      } else {
        return null;
      }
    },
    productHeaders() {
      let productHeaders = [
        { text: "Código de Barras", value: "idItem" },
        { text: "Quantidade", value: "quantidade" },
        { text: "Descrição", value: "descricao" }
      ];
      if (!this.isCarrier) {
        productHeaders.push({ text: "Preço", value: "valorUnitario" });
      }
      return productHeaders;
    }
  },
  watch: {
    isDialogVisible(newValue) {
      this.isVisible = newValue;
    },
    isVisible(newValue) {
      if (!newValue) {
        this.$emit("close-order-details-dialog");
      }
    }
  }
};
</script>

<style></style>

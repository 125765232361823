<template>
  <PageHolder
    breadCombsText="Home - Transportadoras - Entregadores"
    hideLoading
  >
    <v-row>
      <h3>Entregadores</h3>
    </v-row>
    <v-row>
      <RegistersTable
        :clickOnPrimaryButtom="navigateToCreate"
        :clickOnDeleteItem="deleteItem"
        :clickOnEditItem="editItem"
        :headers="headers"
        :items="deliverymen"
        :pageSize="pageSize"
        :itemsLength="deliverymenCount"
        :updatePage="requestItems"
        :updateOptions="updateTableOptions"
        primaryButtomLabel="Novo entregador"
      />
    </v-row>

    <ConfirmationDialog
      ref="dialog"
      question="Deseja mesmo excluir esse entregador?"
      confirmButtomLabel="Excluir"
      :handleConfirm="confirmDeleteItem"
    />
  </PageHolder>
</template>

<script>
import { deliverymenRoutesPaths } from "../routes";
export default {
  data: () => ({
    pageSize: 10,
    headers: [
      {
        text: "Nome",
        value: "usuario.nome"
      },
      { text: "Transportadora", value: "transportadora.nome" },
      { text: "Ações", value: "actions", sortable: false }
    ]
  }),
  computed: {
    deliverymen() {
      return this.$store.state.deliverymenStore.deliverymen;
    },
    deliverymenCount() {
      return this.$store.state.deliverymenStore.deliverymenCount;
    }
  },
  mounted() {
    this.requestItems(1);
  },
  methods: {
    editItem(item) {
      this.$router.push(
        deliverymenRoutesPaths.editDeliveryman.replace(":id", item.id)
      );
    },
    deleteItem(item) {
      this.deliverymanBeingDeleted = item;
      this.$refs.dialog.openDialog();
    },
    async confirmDeleteItem() {
      if (this.pageSize === -1) {
        this.$store.dispatch("deleteDeliveryman", {
          page: this.page,
          pageSize: this.deliverymenCount,
          deliveryman: this.deliverymanBeingDeleted
        });
      } else {
        this.$store.dispatch("deleteDeliveryman", {
          page: this.page,
          pageSize: this.pageSize,
          deliveryman: this.deliverymanBeingDeleted
        });
      }
    },
    navigateToCreate: function() {
      this.$router.push(deliverymenRoutesPaths.createDeliveryman);
    },
    requestItems(page) {
      this.page = page - 1;
      if (this.pageSize === -1) {
        this.$store.dispatch("requestDeliverymen", {
          page: this.page,
          pageSize: this.deliverymenCount
        });
      } else {
        this.$store.dispatch("requestDeliverymen", {
          page: this.page,
          pageSize: this.pageSize
        });
      }
    },
    updateTableOptions(options) {
      this.pageSize = options.itemsPerPage;
      this.requestItems(options.page);
    }
  }
};
</script>

<style></style>

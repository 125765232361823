import Vue from "vue";
import Vuex from "vuex";
import { pageHolderModule } from "./page_holder_module";
import { expeditionsStore } from "../modules/expeditions/expeditions_store";
import { usersStore } from "../modules/users/users_store";
import { carriersStore } from "../modules/carrier/carriers_store";
import { deliverymenStore } from "../modules/deliverymen/deliverymen_store";
import { ordersStore } from "../modules/orders/orders_store";
import { authStore } from "../modules/auth/auth_store";
import { serviceStore } from "../service/service_store";
import { dashboardStore } from "../modules/dashboard/dashboard_store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authStore,
    pageHolderModule,
    expeditionsStore,
    usersStore,
    carriersStore,
    deliverymenStore,
    ordersStore,
    serviceStore,
    dashboardStore
  }
});

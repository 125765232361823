<template>
  <v-app>
    <v-main>
      <v-container fluid style="height: 100vh">
        <v-layout fill-height>
          <v-row align="center">
            <v-col offset-md="7" md="4">
              <v-card class="login_card">
                <v-card-text>
                  <v-container>
                    <v-row justify="space-around">
                      <v-col class="d-flex justify-center">
                        <v-img
                          src="../../../assets/logo_with_text2.png"
                          maxHeight="8rem"
                          contain
                        />
                      </v-col>
                    </v-row>

                    <v-card-subtitle class="text-center">
                      Insira os dados para realizar o login
                    </v-card-subtitle>

                    <v-form ref="form">
                      <v-text-field
                        prepend-icon="mdi-email"
                        label="Login"
                        v-model="username"
                      />

                      <v-text-field
                        prepend-icon="mdi-lock"
                        label="Senha"
                        v-model="password"
                        type="password"
                        v-on:keyup.enter="handleLoginButton()"
                      />

                      <div class="d-flex justify-center">
                        <h4 v-show="failedToLogin" class="error-message">
                          Usuário ou senha incorretos!
                        </h4>
                      </div>

                      <router-link to="/forgot-password"
                        >Esqueceu a senha?</router-link
                      >

                      <v-container>
                        <v-row justify="space-around">
                          <v-col class="d-flex justify-center">
                            <v-btn
                              color="secondary"
                              large
                              depressed
                              @click="handleLoginButton()"
                            >
                              <span class="primary--text"> Entrar </span>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-expand-x-transition>
                        <v-progress-linear
                          v-show="isHavingProgress"
                          class="mt-4"
                          indeterminate
                        />
                      </v-expand-x-transition>
                    </v-form>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    username: null,
    password: null
  }),
  mounted() {
    this.$store.dispatch("logout");
  },
  methods: {
    handleLoginButton() {
      this.$store.dispatch("login", {
        username: this.username,
        password: this.password
      });
    }
  },
  computed: {
    isHavingProgress() {
      return this.$store.state.authStore.isHavingProgress;
    },
    failedToLogin() {
      return this.$store.state.authStore.failedToLogin;
    }
  }
};
</script>

<style>
.v-main__wrap {
  background: url("../../../assets/login-page_background.jpeg");
  background-size: 100%;
}
.error-message {
  color: #d50000;
}
</style>

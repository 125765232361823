export const userTypes = {
  admin: "ADM_SISTEMA",
  operator: "OPERADOR_EXPEDICAO",
  carrier: "ADM_TRANSPORTADORA",
  deliveryman: "ENTREGADOR_TRANSPORTADORA"
};

export function formatUsersProfile(user) {
  const userProfile = {
    [userTypes.admin]: "Administrador do sistema",
    [userTypes.operator]: "Operador de expedição",
    [userTypes.carrier]: "Administrador da transportadora"
  }[user.perfil];

  return Object.assign({ ...user }, { perfil: userProfile });
}

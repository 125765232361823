<template>
  <v-app>
    <v-app-bar dark color="#273B61" prominent absolute dense app>
      <v-container fill-height fluid>
        <v-row align="center" justify="center">
          <v-btn icon class="hidden-xs-only">
            <v-icon>mdi-home-outline</v-icon>
          </v-btn>
          <h6>{{ breadCombsText }}</h6>
          <v-spacer />
          <v-icon>mdi-bell-outline</v-icon>
          <h6 class="ma-4">{{ username }}</h6>
        </v-row>
      </v-container>

      <v-progress-linear
        :active="isHavingProgress && !hideLoading"
        :indeterminate="isHavingProgress"
        absolute
        bottom
        color="secondary"
      />
    </v-app-bar>

    <v-navigation-drawer class="primaryDark" dark app>
      <v-list>
        <v-list-item class="px-2 justify-center ma-8">
          <v-img max-width="200" src="../assets/logo_with_text1.png"></v-img>
        </v-list-item>

        <v-treeview
          v-model="tree"
          open-all
          :items="sidebarItems"
          item-key="name"
          expand-icon=""
        >
          <template v-slot:prepend="{ item }">
            <v-icon v-if="!item.icon">
              {{ "mdi-package-variant-closed" }}
            </v-icon>
            <v-icon v-else>
              {{ item.icon }}
            </v-icon>
          </template>

          <template slot="label" slot-scope="{ item }">
            <a @click="navigateWithItem(item)" class="white--text">{{
              item.name
            }}</a>
          </template>
        </v-treeview>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid fill-height class="white pa-8">
        <v-layout>
          <v-col>
            <v-alert
              v-show="isShowingAlert"
              transition="scale-transition"
              :type="alertType"
              class="pr-10"
            >
              {{ alertMessage }}
            </v-alert>
            <slot />
          </v-col>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { usersRoutesPaths } from "../modules/users/routes";
import { expeditionsRoutesPaths } from "../modules/expeditions/routes";
import { carriersRoutesPaths } from "../modules/carrier/routes";
import { deliverymenRoutesPaths } from "../modules/deliverymen/routes";
import { ordersRoutesPaths } from "../modules/orders/routes";
import { authRoutesPaths } from "../modules/auth/routes";
import { dashboardRoutesPaths } from "../modules/dashboard/routes";
import { userTypes } from "../modules/users/utils";
export default {
  name: "PageHolder",
  props: {
    breadCombsText: String,
    hideLoading: Boolean
  },
  data: () => ({
    tree: []
  }),
  methods: {
    navigateWithItem: function(item) {
      this.$router.push(item.route);
    }
  },
  computed: {
    isShowingAlert() {
      return this.$store.state.pageHolderModule.isShowingAlert;
    },
    alertMessage() {
      return this.$store.state.pageHolderModule.alertMessage;
    },
    alertType() {
      return this.$store.state.pageHolderModule.alertType;
    },
    username() {
      return this.$store.state.authStore.username;
    },
    isHavingProgress() {
      return this.$store.getters.isHavingProgress;
    },
    sidebarItems() {
      const userType = this.$store.state.authStore.userType;
      if (userType == userTypes.admin) {
        return [
          {
            name: "Início",
            icon: "mdi-home-outline",
            route: dashboardRoutesPaths.dashboard
          },
          {
            name: "Pedidos",
            icon: "mdi-cart-outline",
            route: ordersRoutesPaths.orders
          },
          {
            name: "Novo Pedido",
            icon: "mdi-file-document-outline",
            route: ordersRoutesPaths.createOrder
          },
          {
            name: "Usuários",
            icon: "mdi-account-outline",
            route: usersRoutesPaths.users
          },
          {
            name: "Transportadoras",
            icon: "mdi-store-outline",
            route: carriersRoutesPaths.carriers
          },
          {
            name: "Expedições",
            icon: "mdi-package-variant-closed",
            route: expeditionsRoutesPaths.expeditions
          },
          {
            name: "Logout",
            icon: "mdi-logout-variant",
            route: authRoutesPaths.login
          }
        ];
      }
      if (userType == userTypes.carrier) {
        return [
          {
            name: "Início",
            icon: "mdi-home-outline",
            route: dashboardRoutesPaths.dashboard
          },
          {
            name: "Pedidos",
            icon: "mdi-cart-outline",
            route: ordersRoutesPaths.orders
          },
          {
            name: "Agentes (Entregadores)",
            icon: "mdi-motorbike",
            route: deliverymenRoutesPaths.deliverymen
          },
          {
            name: "Logout",
            icon: "mdi-logout-variant",
            route: authRoutesPaths.login
          }
        ];
      }
      if (userType == userTypes.operator) {
        return [
          {
            name: "Início",
            icon: "mdi-home-outline",
            route: dashboardRoutesPaths.dashboard
          },
          {
            name: "Pedidos",
            icon: "mdi-cart-outline",
            route: ordersRoutesPaths.orders
          },
          {
            name: "Novo Pedido",
            icon: "mdi-file-document-outline",
            route: ordersRoutesPaths.createOrder
          },
          {
            name: "Usuários",
            icon: "mdi-account-outline",
            route: usersRoutesPaths.users
          },
          {
            name: "Transportadoras",
            icon: "mdi-store-outline",
            route: carriersRoutesPaths.carriers
          },
          {
            name: "Logout",
            icon: "mdi-logout-variant",
            route: authRoutesPaths.login
          }
        ];
      }

      return [];
    }
  }
};
</script>

<style>
.v-alert {
  position: fixed !important;
  right: 2rem;
  top: 2rem;
  z-index: 100;
}
</style>

import router from "../router";

export const pageHolderModule = {
  state: () => ({
    isShowingAlert: false,
    progressCount: 0,
    alertMessage: "",
    alertType: "success"
  }),
  mutations: {
    setIsShowingAlert(state, value) {
      state.isShowingAlert = value;
    },
    setAlertMessage(state, value) {
      state.alertMessage = value;
    },
    setAlertType(state, value) {
      state.alertType = value;
    },
    setIsHavingProgress(state, value) {
      state.progressCount += value ? 1 : -1;
      if (state.progressCount < 0) {
        state.progressCount = 0;
      }
    },
    navigateBack() {
      router.back();
    },
    navigateToLogin() {
      if (router.currentRoute.path !== "/login") {
        router.push("/login");
      }
    }
  },
  getters: {
    isHavingProgress: state => state.progressCount > 0
  },
  actions: {
    displayAlert({ commit }, { message, type, time }) {
      commit("setAlertMessage", message);
      commit("setAlertType", type);
      commit("setIsShowingAlert", true);
      setTimeout(
        () => {
          commit("setIsShowingAlert", false);
          commit("setAlertMessage", "");
          commit("setAlertType", "success");
        },
        time != null ? time : 3000
      );
    }
  }
};

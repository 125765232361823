import immutable from "immutable";

import { getRequestIdAndIncrementCounter } from "../../service/service_store";
import { convertBase64ToArrayOfBytes } from "./utils";
import { userTypes } from "../users/utils";
import { clearState } from "../../utils/vuex";
const initialState = {
  deliverymen: [],
  deliverymenCount: 0
};
export const deliverymenStore = {
  state: () => ({ ...initialState }),
  getters: {
    getDeliveryman: state => id => state.deliverymen.find(d => d.id == id)
  },
  mutations: {
    setDeliverymen(state, { deliverymen, deliverymenCount }) {
      state.deliverymen = deliverymen;
      state.deliverymenCount = deliverymenCount;
    },
    setDeliveryman(state, deliveryman) {
      const index = state.deliverymen.findIndex(d => d.id === deliveryman.id);
      state.deliverymen[index] = deliveryman;
    },
    clearDeliverymanStore(state) {
      clearState(state, initialState);
    }
  },
  actions: {
    async requestDeliverymen(
      { commit, dispatch, getters },
      { page, pageSize }
    ) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = `/entregador/page?pagina=${page}&quantidade=${pageSize}`;
      await dispatch("requestWithAuth", { requestId, route });

      const request = getters.getRequests[requestId];

      const deliverymen = request.body.list;

      const deliverymenCount = request.body.totalItems;
      if (request.ok) {
        commit("setDeliverymen", { deliverymen, deliverymenCount });
      }

      commit("deleteRequest", requestId);
    },
    async submitDeliveryman({ commit, getters, dispatch }, deliveryman) {
      const isNewDeliveryman = !deliveryman.id;

      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      deliveryman.foto = await convertBase64ToArrayOfBytes(
        deliveryman.fotoBase64
      );
      deliveryman.fotoCnh = await convertBase64ToArrayOfBytes(
        deliveryman.fotoCnhBase64
      );
      deliveryman.fotoSelfie = await convertBase64ToArrayOfBytes(
        deliveryman.fotoSelfieBase64
      );

      deliveryman.perfil = "ROLE_" + userTypes.deliveryman;

      const route = `/entregador/${deliveryman.id || ""}`;
      await dispatch("requestWithAuth", {
        requestId,
        route,
        additionalRequestInit: {
          method: isNewDeliveryman ? "POST" : "PUT",
          body: immutable
            .mergeDeep(
              immutable.Map(deliveryman),
              immutable.Map({
                fotoBase64: undefined,
                fotoCnhBase64: undefined,
                fotoSelfieBase64: undefined
              })
            )
            .toJS()
        }
      });

      const request = getters.getRequests[requestId];

      if (request.ok) {
        commit("navigateBack");
        dispatch("displayAlert", {
          message: isNewDeliveryman
            ? "Entregador cadastrado com sucesso!"
            : "Entregador alterado com sucesso!",
          type: "success"
        });
      } else {
        dispatch("displayAlert", {
          message: request.body.message,
          type: "error"
        });
      }

      commit("deleteRequest", requestId);
    },
    async deleteDeliveryman(
      { dispatch, commit, getters },
      { deliveryman, page, pageSize }
    ) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = `/entregador/${deliveryman.id}`;
      await dispatch("requestWithAuth", {
        requestId,
        route,
        additionalRequestInit: {
          method: "DELETE"
        }
      });

      const request = getters.getRequests[requestId];

      if (request.ok) {
        dispatch("requestDeliverymen", { page, pageSize });
      } else {
        dispatch("displayAlert", {
          message: request.body.message,
          type: "error"
        });
      }

      commit("deleteRequest", requestId);
    },
    async requestDeliveryman({ dispatch, commit, getters }, deliveryman) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = `/entregador/${deliveryman.id}`;
      await dispatch("requestWithAuth", {
        requestId,
        route
      });

      const request = getters.getRequests[requestId];

      if (request.ok) {
        commit("setDeliveryman", request.body);
      } else {
        dispatch("displayAlert", {
          message: request.body.message,
          type: "error"
        });
      }

      commit("deleteRequest", requestId);
    }
  }
};

import { getRequestIdAndIncrementCounter } from "../../service/service_store";
import { clearState } from "../../utils/vuex";
const initialState = {
  expeditions: [],
  expeditionsCount: 0
};
export const expeditionsStore = {
  state: () => ({ ...initialState }),
  getters: {
    getExpedition: state => id => state.expeditions.find(e => e.id == id)
  },
  mutations: {
    setExpeditions(state, { expeditions, expeditionsCount }) {
      state.expeditions = expeditions;
      state.expeditionsCount = expeditionsCount;
    },
    clearExpeditionsStore(state) {
      clearState(state, initialState);
    }
  },
  actions: {
    async requestExpeditions(
      { commit, dispatch, getters },
      { page, pageSize, nameFilter }
    ) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });
      const isPaginated = pageSize !== -1 && !nameFilter;

      const route = isPaginated
        ? `/expedicao/page?pagina=${page}&quantidade=${pageSize}`
        : nameFilter
        ? "/expedicao/nome?nome=" + nameFilter
        : "/expedicao";

      await dispatch("requestWithAuth", { requestId, route });

      const request = getters.getRequests[requestId];

      if (request.ok) {
        const expeditionsCount = isPaginated
          ? request.body.totalItems
          : request.body.length;
        commit("setExpeditions", {
          expeditions: isPaginated ? request.body.list : request.body,
          expeditionsCount
        });
      }

      commit("deleteRequest", requestId);
    },
    async submitExpedition({ commit, dispatch, getters }, expedition) {
      const isNewExpedition = !expedition.id;

      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = `/expedicao/${isNewExpedition ? "" : expedition.id}`;
      await dispatch("requestWithAuth", {
        requestId,
        route,
        additionalRequestInit: {
          method: isNewExpedition ? "POST" : "PUT",
          body: expedition
        }
      });

      const request = getters.getRequests[requestId];

      if (request.ok) {
        commit("navigateBack");
        dispatch("displayAlert", {
          message: isNewExpedition
            ? "Expedição cadastrada com sucesso!"
            : "Expedição editada com sucesso!",
          type: "success"
        });
      } else {
        dispatch("displayAlert", {
          message: request.body.message,
          type: "error"
        });
      }

      commit("deleteRequest", requestId);
    },
    async deleteExpedition({ dispatch, commit, getters }, expedition) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = `/expedicao/${expedition.id}`;
      await dispatch("requestWithAuth", {
        requestId,
        route,
        additionalRequestInit: {
          method: "DELETE"
        }
      });

      const request = getters.getRequests[requestId];

      if (request.ok) {
        dispatch("requestExpeditions", { page: 0, pageSize: 10000 });
      } else {
        dispatch("displayAlert", {
          message: request.body.message,
          type: "error"
        });
      }

      commit("deleteRequest", requestId);
    }
  }
};

import { getRequestIdAndIncrementCounter } from "../../service/service_store";
import { clearState } from "../../utils/vuex";
const initialState = {
  users: [],
  usersCount: 0
};
export const usersStore = {
  state: () => ({ ...initialState }),
  getters: {
    getUser: state => id => state.users.find(u => u.id == id)
  },
  mutations: {
    setUsers(state, { users, usersCount }) {
      state.users = users;
      state.usersCount = usersCount;
    },
    clearUserStore(state) {
      clearState(state, initialState);
    }
  },
  actions: {
    async requestUsers(
      { commit, dispatch, getters },
      { page, pageSize, nameFilter }
    ) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });
      const isPaginatedRequest = pageSize !== -1 && !nameFilter;

      const route = isPaginatedRequest
        ? `/usuario/page?pagina=${page}&quantidade=${pageSize}`
        : nameFilter
        ? "/usuario/nome?nome=" + nameFilter
        : "/usuario";
      await dispatch("requestWithAuth", { requestId, route });

      const request = getters.getRequests[requestId];

      if (request.ok) {
        const users = (isPaginatedRequest
          ? request.body.list
          : request.body
        ).map(u => {
          u.perfil = u.perfil.replace("ROLE_", "");
          return u;
        });
        const usersCount = isPaginatedRequest
          ? request.body.totalItems
          : users.length;

        commit("setUsers", { users, usersCount });
      }

      commit("deleteRequest", requestId);
    },
    async submitUser({ commit, dispatch, getters }, user) {
      const isNewUser = !user.id;

      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = `/usuario/${user.id || ""}`;
      await dispatch("requestWithAuth", {
        requestId,
        route,
        additionalRequestInit: {
          method: isNewUser ? "POST" : "PUT",
          body: user
        }
      });

      const request = getters.getRequests[requestId];

      if (request.ok) {
        commit("navigateBack");
      } else {
        dispatch("displayAlert", {
          message: request.body.message,
          type: "error"
        });
      }

      commit("deleteRequest", requestId);
    },
    async deleteUser({ dispatch, commit, getters }, user) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = `/usuario/${user.id}`;
      await dispatch("requestWithAuth", {
        requestId,
        route,
        additionalRequestInit: {
          method: "DELETE"
        }
      });

      const request = getters.getRequests[requestId];

      if (request.ok) {
        dispatch("requestUsers", { page: 0, pageSize: -1 });
      } else {
        dispatch("displayAlert", {
          message: request.body.message,
          type: "error"
        });
      }

      commit("deleteRequest", requestId);
    }
  }
};

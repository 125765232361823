<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  created() {
    this.refreshUser();
  },
  methods: {
    refreshUser() {
      const userData = JSON.parse(sessionStorage.getItem("access_info"));
      if (userData) {
        this.$store.commit("setUserData", userData);
      }
    }
  }
};
</script>

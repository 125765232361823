<template>
  <PageHolder
    :breadCombsText="
      `Home - Entregador - ${isNewDeliveryman ? 'Novo' : 'Editar'}`
    "
  >
    <v-card class="flex-grow-1">
      <v-col>
        <v-row class="pl-8 pr-8 pa-4">
          <h3>{{ isNewDeliveryman ? "Novo" : "Editar" }} Entregador</h3>
          <v-btn
            class="ml-auto"
            outlined
            color="#8C8C8C"
            @click="handleCancelButtom()"
            >CANCELAR</v-btn
          >
          <v-btn dark color="#303030" class="ml-4" @click="handleSaveButtom()">
            SALVAR
          </v-btn>
        </v-row>
        <v-divider />
        <v-form ref="form" v-model="isFormValid">
          <v-row class="pl-8 pr-8 pa-4">
            <v-col lg="6">
              <v-card>
                <v-card-text
                  class="ma-auto deliveryman-flex justify-center flex-wrap"
                >
                  <foto-button
                    label="Foto*"
                    v-model="deliveryman.fotoBase64"
                    ref="photoField"
                    class="ma-2"
                  />
                  <foto-button
                    label="Foto CNH"
                    v-model="deliveryman.fotoCnhBase64"
                    class="ma-2"
                  />
                  <foto-button
                    label="Selfie"
                    v-model="deliveryman.fotoSelfieBase64"
                    class="ma-2"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col lg="6">
              <v-row>
                <v-col lg="12">
                  <v-text-field
                    label="Nome*"
                    hint="ex. Vicente Bernardo Gonçalves"
                    v-model="deliveryman.nome"
                    :rules="[rules.required]"
                    persistent-hint
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="12">
                  <v-text-field
                    label="Email*"
                    v-model="deliveryman.email"
                    hint="ex. email@email.com"
                    :rules="[
                      rules.validEmail,
                      rules.required,
                      rules.fieldErrors('email')
                    ]"
                    persistent-hint
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="12">
                  <v-text-field
                    label="Endereço*"
                    v-model="deliveryman.endereco.rua"
                    hint="ex. Rua Liz Sophia Teixeira, 28"
                    :rules="[rules.required]"
                    persistent-hint
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="4">
                  <v-text-field
                    label="Login*"
                    v-model="deliveryman.login"
                    hint="ex. vincente_maia"
                    :rules="[rules.required, rules.fieldErrors('login')]"
                    persistent-hint
                  />
                </v-col>
                <v-col lg="4">
                  <v-text-field
                    :label="isNewDeliveryman ? 'Senha*' : 'Senha'"
                    v-model="deliveryman.senha"
                    required
                    :rules="[
                      rules.minLengthRequired(4),
                      rules.ifCreateIsRequired,
                      rules.fieldErrors('senha')
                    ]"
                    persistent-hint
                    type="password"
                  />
                </v-col>
                <v-col lg="4">
                  <v-text-field
                    :label="
                      isNewDeliveryman
                        ? 'Confirmação de senha*'
                        : 'Confirmação de senha'
                    "
                    v-model="deliveryman.confirmacaoSenha"
                    required
                    :rules="[
                      rules.matchPasswordFieldValue,
                      rules.ifCreateIsRequired,
                      rules.fieldErrors('confirmacaoSenha')
                    ]"
                    persistent-hint
                    type="password"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="4">
                  <v-text-field
                    label="CPF"
                    :rules="[rules.validateCpf]"
                    v-mask="'###.###.###-##'"
                    v-model="deliveryman.cpf"
                    hint="ex. 349.832.308-31"
                    persistent-hint
                  />
                </v-col>
                <v-col lg="4">
                  <v-text-field
                    label="CNH*"
                    :rules="[rules.required, rules.minLengthRequired(11)]"
                    v-model="deliveryman.cnh"
                    hint="ex. 38604584457"
                    persistent-hint
                  />
                </v-col>
                <v-col lg="4">
                  <v-text-field
                    label="Placa do veículo"
                    v-model="deliveryman.placa"
                    hint="ex. PNP-7247"
                    persistent-hint
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="pl-8 pr-8">
            <v-col>
              <h4>Em caso de Emergencia</h4>
            </v-col>
          </v-row>
          <v-row class="pa-8 pt-0">
            <v-col lg="6">
              <v-text-field
                label="Pessoa para contato*"
                v-model="deliveryman.contato.nome"
                :rules="[rules.required]"
                hint="ex. María Clara Silva"
                persistent-hint
              />
            </v-col>
            <v-col lg="3">
              <v-text-field
                label="Contato 1*"
                v-model="deliveryman.contato.contato1"
                :rules="[rules.required, rules.minLengthRequired(10)]"
                hint="ex. (85)1234-7894"
                persistent-hint
              />
            </v-col>
            <v-col lg="3">
              <v-text-field
                label="Contato 2"
                v-model="deliveryman.contato.contato2"
                :rules="[rules.minLengthRequired(10)]"
                hint="ex. (85)1234-7894"
                persistent-hint
              />
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-card>
  </PageHolder>
</template>

<script>
import {
  required,
  requiredIfConditionMatch,
  validEmail,
  validateCpf,
  minLengthRequired,
  matchPasswordFieldValue,
  fieldErrors
} from "../../../utils/formFieldRules";
import { convertArrayOfBytesToBase64 } from "../utils";
export default {
  async mounted() {
    if (this.$route.params.id) {
      await this.$store.dispatch("requestDeliveryman", {
        id: this.$route.params.id
      });
      const requestedDeliveryman = this.$store.getters.getDeliveryman(
        this.$route.params.id
      );

      this.deliveryman.endereco.rua = requestedDeliveryman.usuario.endereco.rua;
      this.deliveryman.nome = requestedDeliveryman.usuario.nome;
      this.deliveryman.email = requestedDeliveryman.usuario.email;
      this.deliveryman.login = requestedDeliveryman.usuario.login;
      this.deliveryman.contato.nome = requestedDeliveryman.usuario.contato.nome;
      this.deliveryman.contato.contato1 =
        requestedDeliveryman.usuario.contato.contato1;
      this.deliveryman.contato.contato2 =
        requestedDeliveryman.usuario.contato.contato2;

      if (requestedDeliveryman.foto) {
        this.deliveryman.fotoBase64 = convertArrayOfBytesToBase64(
          requestedDeliveryman.foto
        );
      }
      if (requestedDeliveryman.fotoCnh) {
        this.deliveryman.fotoCnhBase64 = convertArrayOfBytesToBase64(
          requestedDeliveryman.fotoCnh
        );
      }
      if (requestedDeliveryman.fotoSelfie) {
        this.deliveryman.fotoSelfieBase64 = convertArrayOfBytesToBase64(
          requestedDeliveryman.fotoSelfie
        );
      }
    }
  },
  data: function() {
    const deliveryman = Object.assign(
      {
        fotoBase64: null,
        fotoCnhBase64: null,
        fotoSelfieBase64: null,
        nome: null,
        email: null,
        endereco: {
          rua: null
        },
        login: null,
        senha: null,
        confirmacaoSenha: null,
        cpf: null,
        cnh: null,
        placa: null,
        contato: {
          nome: null,
          contato1: null,
          contato2: null
        }
      },
      this.$store.getters.getDeliveryman(this.$route.params.id) ?? {}
    );
    if (deliveryman.foto) {
      deliveryman.fotoBase64 = convertArrayOfBytesToBase64(deliveryman.foto);
    }
    if (deliveryman.fotoCnh) {
      deliveryman.fotoCnhBase64 = convertArrayOfBytesToBase64(
        deliveryman.fotoCnh
      );
    }
    if (deliveryman.fotoSelfie) {
      deliveryman.fotoSelfieBase64 = convertArrayOfBytesToBase64(
        deliveryman.fotoSelfie
      );
    }
    return {
      isNewDeliveryman: !this.$route.params.id,
      isFormValid: false,
      deliveryman
    };
  },
  computed: {
    rules() {
      const apiErrors = this.$store.getters.getApiErrors;
      const ifCreateIsRequired = requiredIfConditionMatch(
        this.isNewDeliveryman
      );

      return {
        required,
        ifCreateIsRequired,
        validEmail,
        validateCpf,
        minLengthRequired,
        matchPasswordFieldValue: matchPasswordFieldValue(
          this.deliveryman.senha
        ),
        fieldErrors: fieldErrors(apiErrors)
      };
    }
  },
  methods: {
    handleSaveButtom() {
      this.$store.commit("clearApiErrors");
      setTimeout(() => {
        this.ifFormValidSubmitDeliveryman();
      }, 200);
    },
    ifFormValidSubmitDeliveryman() {
      if (this.isFormValid && this.deliveryman.fotoBase64) {
        this.$store.dispatch("submitDeliveryman", this.deliveryman);
      } else {
        this.$refs.form.validate();
        this.$refs.photoField.textClass = "red--text";
        this.$store.dispatch("displayAlert", {
          message: "Formulário preenchido incorretamente!",
          type: "error"
        });
      }
    },
    handleCancelButtom() {
      this.$store.dispatch("displayAlert", {
        message: "Operação cancelada!",
        type: "warning"
      });
      this.$router.go(-1);
    }
  }
};
</script>

<style></style>

<template>
  <PageHolder
    :breadCombsText="
      `Home - Expedições - ${isNewExpedition ? 'Nova' : 'Editar'}`
    "
  >
    <v-card class="flex-grow-1">
      <v-col>
        <v-row class="pl-8 pr-8 pa-4">
          <h3>{{ isNewExpedition ? "Nova" : "Editar" }} Expedição</h3>
          <v-btn
            class="ml-auto"
            outlined
            color="#8C8C8C"
            @click="handleCancelButtom()"
            >CANCELAR</v-btn
          >
          <v-btn
            dark
            color="#303030"
            class="ml-4"
            @click="clearApiErrorsAndSubmitExpedition()"
          >
            SALVAR
          </v-btn>
        </v-row>
        <v-divider />
        <v-row class="pl-8 pr-8 pa-4">
          <v-form ref="form" class="flex-grow-1" v-model="isFormValid">
            <v-row>
              <v-col lg="6">
                <v-text-field
                  label="Nome*"
                  v-model="expedition.nome"
                  required
                  :rules="[rules.required, rules.fieldErrors('nome')]"
                  hint="ex. Marcos Vinicius e Hugo Transportes"
                  persistent-hint
                />
              </v-col>
              <v-col lg="3">
                <v-select
                  :items="states"
                  item-text="state"
                  item-value="abbr"
                  label="Estado*"
                  v-model="expedition.endereco.uf"
                  :rules="[rules.required, rules.fieldErrors('endereco.uf')]"
                  single-line
                />
              </v-col>
              <v-col lg="3">
                <v-text-field
                  label="Missão (Cidade)*"
                  required
                  v-model="expedition.endereco.missao"
                  :rules="[
                    rules.required,
                    rules.fieldErrors('endereco.missao')
                  ]"
                  hint="ex. Fortaleza"
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="6">
                <v-text-field
                  label="Bairro*"
                  required
                  v-model="expedition.endereco.bairro"
                  :rules="[
                    rules.required,
                    rules.fieldErrors('endereco.bairro')
                  ]"
                  hint="ex. Antônio Bezerra"
                  persistent-hint
                />
              </v-col>
              <v-col lg="4">
                <v-text-field
                  label="Rua*"
                  required
                  v-model="expedition.endereco.rua"
                  :rules="[rules.required, rules.fieldErrors('endereco.rua')]"
                  hint="ex. Liz Sophia Teixeira"
                  persistent-hint
                />
              </v-col>
              <v-col lg="2">
                <v-text-field
                  label="Número*"
                  required
                  v-model="expedition.endereco.numero"
                  :rules="[
                    rules.required,
                    rules.fieldErrors('endereco.numero')
                  ]"
                  hint="ex. 16"
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="12">
                <v-text-field
                  label="Complemento"
                  v-model="expedition.endereco.complemento"
                  :rules="[rules.fieldErrors('endereco.complemento')]"
                  hint="ex. Bloco A"
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="6">
                <v-text-field
                  label="Pessoa para contato*"
                  required
                  v-model="expedition.contato.nome"
                  :rules="[rules.required, rules.fieldErrors('contato.nome')]"
                  hint="ex. Maria Clara Záfon"
                  persistent-hint
                />
              </v-col>
              <v-col lg="3">
                <v-text-field
                  label="Contato 1*"
                  required
                  v-model="expedition.contato.contato1"
                  :rules="[
                    rules.required,
                    rules.fieldErrors('contato.contato1')
                  ]"
                  hint="ex. (85)1234-7894"
                  persistent-hint
                />
              </v-col>
              <v-col lg="3">
                <v-text-field
                  label="Contato 2"
                  v-model="expedition.contato.contato2"
                  :rules="[rules.fieldErrors('contato.contato2')]"
                  hint="ex. (85)1234-7894"
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="6">
                <v-text-field
                  label="Email"
                  hint="ex. email@email.com"
                  v-model="expedition.email"
                  :rules="[rules.validEmail, rules.fieldErrors('email')]"
                  persistent-hint
                />
              </v-col>
            </v-row>
          </v-form>
        </v-row>
      </v-col>
    </v-card>
  </PageHolder>
</template>

<script>
import {
  required,
  validEmail,
  fieldErrors
} from "../../../utils/formFieldRules";
import states from "../../../enums/states";
export default {
  data: function() {
    const expedition = Object.assign(
      {
        nome: null,
        email: null,
        endereco: {
          uf: null,
          missao: null,
          bairro: null,
          rua: null,
          numero: null,
          complemento: null
        },
        contato: {
          nome: null,
          contato1: null,
          contato2: null
        }
      },
      this.$store.getters.getExpedition(this.$route.params.id) ?? {}
    );
    return {
      expedition,
      isNewExpedition: !this.$route.params.id,
      isFormValid: false,
      states: Object.keys(states)
        .sort((a, b) => a > b)
        .map(s => ({ abbr: s, state: states[s] }))
    };
  },
  methods: {
    clearApiErrorsAndSubmitExpedition() {
      this.$store.commit("clearApiErrors");
      setTimeout(() => this.submitExpeditionIfFormIsValid(), 200);
    },
    submitExpeditionIfFormIsValid() {
      if (this.isFormValid) {
        this.$store.dispatch("submitExpedition", this.expedition);
      } else {
        this.$refs.form.validate();
        this.$store.dispatch("displayAlert", {
          message: "Formulário preenchido incorretamente!",
          type: "error"
        });
      }
    },
    handleCancelButtom() {
      this.$store.dispatch("displayAlert", {
        message: "Operação cancelada!",
        type: "warning"
      });
      this.$router.go(-1);
    }
  },
  computed: {
    rules() {
      return {
        required,
        validEmail,
        fieldErrors: fieldErrors(this.$store.getters.getApiErrors)
      };
    }
  }
};
</script>

<style></style>

import { getRequestIdAndIncrementCounter } from "../../service/service_store";
import { clearState } from "../../utils/vuex";
import { formatMoneyValue } from "../../utils/misc";

const initialState = {
  agents: [],
  carriers: [],
  orders: [],
  ordersCount: 0,
  searchedOrder: null
};
export const ordersStore = {
  state: () => ({ ...initialState }),
  mutations: {
    setAgents(state, agents) {
      state.agents = agents;
    },
    setCarriers(state, carriers) {
      state.carriers = carriers;
    },
    setOrders(state, { orders, ordersCount }) {
      state.orders = orders;
      state.ordersCount = ordersCount;
    },
    clearOrderStore(state) {
      clearState(state, initialState);
    },
    setSearchedOrder(state, searchedOrder) {
      state.searchedOrder = searchedOrder;
    }
  },
  actions: {
    async requestOrders(
      { dispatch, commit, getters },
      { page, pageSize, filter }
    ) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const statusList = [];
      for (let statusObject of filter.status) {
        statusList.push(statusObject.value);
      }

      const route = `/pedido/relatorio/page?idTransportadora=${filter.carrierId}&dtinicio=${filter.startDate}T00:00:00&dtfim=${filter.endDate}T23:59:59&status=${statusList}&cidade=${filter.city}&nomeCliente=${filter.clientName}&idAgente=${filter.agentId}&pagina=${page}&quantidade=${pageSize}`;

      await dispatch("requestWithAuth", {
        requestId,
        route,
        additionalRequestInit: {
          method: "GET"
        }
      });

      const request = getters.getRequests[requestId];

      if (request.ok) {
        const orders = request.body.list;
        const ordersCount = request.body.totalItems;

        for (let order of orders) {
          if (!order.transportadora) {
            order.transportadora = { nome: "" };
          }
          if (!order.agente) {
            order.agente = {
              usuario: { nome: "" }
            };
          }

          order.valorTotal = formatMoneyValue(parseFloat(order.valorTotal));
          order.precoTransporte = formatMoneyValue(
            parseFloat(order.precoTransporte)
          );
          order.dtCadastro = order.dtCadastro.split(" ")[0];
          order.dtCadastro = order.dtCadastro.replaceAll("-", "/");

          for (let product of order.itemPedidos) {
            product.valorUnitario = formatMoneyValue(
              parseFloat(product.valorUnitario)
            );
          }
        }

        commit("setOrders", { orders, ordersCount });
      } else if (request.status === 404) {
        dispatch("displayAlert", {
          message: "Não foram encontrados pedidos com essas especificações.",
          type: "warning"
        });
        const orders = [];
        const ordersCount = 0;

        commit("setOrders", { orders, ordersCount });
      }

      commit("deleteRequest", requestId);
    },

    async requestSearchOrder(
      { dispatch, commit, getters },
      { barcode, expeditionId }
    ) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = "/pedido/pesquisar";

      await dispatch("requestWithAuth", {
        requestId,
        route,
        additionalRequestInit: {
          method: "POST",
          body: { idExpedicao: expeditionId, codigoBarras: barcode }
        }
      });

      const request = getters.getRequests[requestId];

      if (request.ok) {
        commit("setSearchedOrder", request.body);
        dispatch("displayAlert", {
          message: "Pedido realizado com sucesso",
          type: "success"
        });
      } else if (request.status == 400) {
        dispatch("displayAlert", {
          message: request.body.message,
          type: "error"
        });
      }

      if (request.status === 404) {
        dispatch("displayAlert", {
          message: "Pedido não encontrado!",
          type: "warning"
        });
      }

      commit("deleteRequest", requestId);
    },

    async requestCarriersList({ dispatch, commit, getters }) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = "/transportadora";

      await dispatch("requestWithAuth", {
        requestId,
        route,
        additionalRequestInit: {
          method: "GET"
        }
      });

      const request = getters.getRequests[requestId];

      if (request.ok) {
        commit("setCarriers", request.body);
      } else if (request.status == 400) {
        dispatch("displayAlert", {
          message: request.body.message,
          type: "error"
        });
      }

      if (request.status === 404) {
        dispatch("displayAlert", {
          message: "Transportadora não encontradas!",
          type: "warning"
        });
      }

      commit("deleteRequest", requestId);
    },

    async requestAgentsList({ dispatch, commit, getters }, { carrierId }) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = `/entregador/transportadora?idTransportadora=${carrierId}`;

      await dispatch("requestWithAuth", {
        requestId,
        route,
        additionalRequestInit: {
          method: "GET"
        }
      });

      const request = getters.getRequests[requestId];

      if (request.ok) {
        commit("setAgents", request.body);
      } else if (request.status == 400) {
        dispatch("displayAlert", {
          message: request.body.message,
          type: "error"
        });
      }

      if (request.status === 404) {
        commit("setAgents", null);
        dispatch("displayAlert", {
          message: "Agentes não encontrados!",
          type: "warning"
        });
      }

      commit("deleteRequest", requestId);
    },

    async updateShipping(
      { dispatch, commit, getters },
      { orderId, carrierId, agentId, shippingFee }
    ) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = `/pedido/update/${orderId}`;

      let shippingBody = {};
      shippingBody["idTransportadora"] = `${carrierId}`;

      if (agentId) {
        shippingBody["idAgente"] = `${agentId}`;
      }

      if (shippingFee) {
        shippingFee = shippingFee.replace(",", ".");
        shippingBody["precoTransporte"] = `${shippingFee}`;
      }

      await dispatch("requestWithAuth", {
        requestId,
        route,
        additionalRequestInit: {
          method: "PUT",
          body: shippingBody
        }
      });

      const request = getters.getRequests[requestId];

      if (request.ok) {
        dispatch("displayAlert", {
          message: "Detalhes de transporte atualizados.",
          type: "success"
        });
      } else {
        dispatch("displayAlert", {
          message: request.body.message,
          type: "error"
        });
      }

      commit("deleteRequest", requestId);
    }
  }
};

<template>
  <PageHolder breadCombsText="Home - Transportadoras" hideLoading>
    <v-row no-gutters>
      <v-col lg="6">
        <h3>Transportadoras</h3>
      </v-col>
      <v-col lg="6">
        <v-text-field
          label="Pesquisar Transportadora"
          v-model="nameFilter"
          hint="ex. Correios"
          persistent-hint
        />
      </v-col>
    </v-row>
    <v-row>
      <RegistersTable
        :clickOnPrimaryButtom="navigateToCreateCarrier"
        :clickOnDeleteItem="deleteItem"
        :clickOnEditItem="editItem"
        :headers="headers"
        :items="carriers"
        :pageSize="pageSize"
        :itemsLength="carriersCount"
        :updatePage="requestItems"
        :updateOptions="updateTableOptions"
        primaryButtomLabel="Nova transportadora"
      />
    </v-row>

    <ConfirmationDialog
      ref="dialog"
      question="Deseja mesmo excluir essa transportadora?"
      confirmButtomLabel="Excluir"
      :handleConfirm="confirmDeleteItem"
      cancelMessage="Exclusão cancelada!"
    />
  </PageHolder>
</template>

<script>
import { carriersRoutesPaths } from "../routes";

export default {
  data: () => ({
    carrierBeingDeleted: null,
    nameFilter: null,
    headers: [
      {
        text: "Nome",
        value: "nome"
      },
      { text: "CPF / CNPJ", value: "cpfOuCnpj" },
      { text: "Estado", value: "endereco.uf" },
      { text: "Missão (Cidade)", value: "endereco.missao" },
      { text: "Expedição", value: "expedicao.nome" },
      { text: "Ações", value: "actions", sortable: false }
    ],
    pageSize: 10
  }),
  mounted() {
    this.requestItems(1);
  },
  methods: {
    editItem(item) {
      this.$router.push(
        carriersRoutesPaths.editCarrier.replace(":id", item.id)
      );
    },
    deleteItem(item) {
      this.carrierBeingDeleted = item;
      this.$refs.dialog.openDialog();
    },
    confirmDeleteItem() {
      this.$store.dispatch("deleteCarrier", this.carrierBeingDeleted);
    },
    navigateToCreateCarrier() {
      this.$router.push(carriersRoutesPaths.createCarrier);
    },
    async requestItems(page) {
      if (this.nameFilter && this.nameFilter.length >= 3) {
        await this.$store.dispatch("requestCarriers", {
          nameFilter: this.nameFilter
        });
        this.pageSize = -1;
      } else {
        this.$store.dispatch("requestCarriers", {
          page: page - 1,
          pageSize: this.pageSize
        });
      }
    },
    updateTableOptions(options) {
      this.pageSize = options.itemsPerPage;
      this.requestItems(options.page);
    }
  },
  computed: {
    carriers() {
      return this.$store.state.carriersStore.carriers;
    },
    carriersCount() {
      return this.$store.state.carriersStore.carriersCount;
    }
  },
  watch: {
    nameFilter() {
      if (this.nameFilter && this.nameFilter.length >= 3) {
        this.requestItems();
      }
    }
  }
};
</script>

<style></style>

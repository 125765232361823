import CreateOrderPage from "./views/CreateOrderPage";
import OrdersPage from "./views/OrdersPage";

export const ordersRoutesPaths = {
  orders: "/orders",
  createOrder: "/orders/create"
};

export const ordersRoutes = [
  {
    path: ordersRoutesPaths.orders,
    name: "Orders",
    component: OrdersPage
  },
  {
    path: ordersRoutesPaths.createOrder,
    name: "Create Order",
    component: CreateOrderPage
  }
];

import DashboardPage from "./DashboardPage";

export const dashboardRoutesPaths = {
  dashboard: "/"
};

export const dashboardRoutes = [
  {
    path: dashboardRoutesPaths.dashboard,
    name: "Dashboard",
    component: DashboardPage
  }
];

<template>
  <PageHolder breadCombsText="Home - Expedições" hideLoading>
    <v-row no-gutters>
      <v-col lg="6">
        <h3>Expedições</h3>
      </v-col>
      <v-col lg="6">
        <v-text-field
          label="Pesquisar Expedição"
          v-model="nameFilter"
          hint="ex. Fortaleza"
          persistent-hint
        />
      </v-col>
    </v-row>
    <v-row>
      <RegistersTable
        :clickOnPrimaryButtom="navigateToCreateExpedition"
        :clickOnDeleteItem="deleteItem"
        :clickOnEditItem="editItem"
        :headers="headers"
        :items="expeditions"
        :pageSize="pageSize"
        :itemsLength="expeditionsCount"
        :updatePage="requestItems"
        :updateOptions="updateTableOptions"
        primaryButtomLabel="Nova Expedição"
      />
    </v-row>

    <ConfirmationDialog
      ref="dialog"
      question="Deseja mesmo excluir essa expedição?"
      confirmButtomLabel="Excluir"
      :handleConfirm="confirmDeleteItem"
      cancelMessage="Exclusão cancelada!"
    />
  </PageHolder>
</template>

<script>
import { expeditionsRoutesPaths } from "../routes";

export default {
  mounted() {
    this.requestItems(1);
  },
  data: () => ({
    nameFilter: null,
    pageSize: 10,
    headers: [
      {
        text: "Nome",
        value: "nome"
      },
      {
        text: "Estado",
        value: "endereco.uf"
      },
      { text: "Missão (Cidade)", value: "endereco.missao" },
      { text: "Ações", value: "actions", sortable: false }
    ]
  }),
  methods: {
    editItem(item) {
      this.$router.push(
        expeditionsRoutesPaths.editExpedition.replace(":id", item.id)
      );
    },
    deleteItem(item) {
      this.expeditionBeingDeleted = item;
      this.$refs.dialog.openDialog();
    },
    confirmDeleteItem() {
      this.$store.dispatch("deleteExpedition", this.expeditionBeingDeleted);
    },
    navigateToCreateExpedition: function() {
      this.$router.push(expeditionsRoutesPaths.createExpedition);
    },
    async requestItems(page) {
      if (this.nameFilter && this.nameFilter.length >= 3) {
        await this.$store.dispatch("requestExpeditions", {
          nameFilter: this.nameFilter
        });
        this.pageSize = -1;
      } else {
        this.$store.dispatch("requestExpeditions", {
          page: page - 1,
          pageSize: this.pageSize
        });
      }
    },
    updateTableOptions(options) {
      this.pageSize = options.itemsPerPage;
      this.requestItems(options.page);
    }
  },
  computed: {
    expeditions() {
      return this.$store.state.expeditionsStore.expeditions;
    },
    expeditionsCount() {
      return this.$store.state.expeditionsStore.expeditionsCount;
    }
  },
  watch: {
    nameFilter() {
      if (this.nameFilter && this.nameFilter.length >= 3) {
        this.requestItems();
      }
    }
  }
};
</script>

<style></style>

export const required = v => !!v || "Campo obrigatório";

export const minLengthRequired = minLength => v =>
  v
    ? v.length >= minLength || `O tamanho mínimo é de ${minLength} caracteres`
    : true;

export const validEmail = v =>
  v ? /.+@.+/.test(v) || "E-mail inválido" : true;

export const validPrice = v =>
  v ? /^\d+([,.][0-9]{1,2})?$/.test(v) || "Valor inválido" : true;

export const requiredIfConditionMatch = condition => v =>
  condition ? !!v || "Campo obrigatório" : true;

export const matchPasswordFieldValue = password => v =>
  password === v || "As senhas não coincidem";

export const fieldErrors = apiErrors => name =>
  (apiErrors.find(e => e.fieldName.toLowerCase() === name.toLowerCase()) || {})
    .message || true;

export function validateCpf(inputCpf) {
  if (!inputCpf) {
    return true;
  }

  const cleanCpf = inputCpf
    .replace(".", "")
    .replace(".", "")
    .replace("-", "");
  const errorMessage = "CPF inválido";
  let sum = 0;
  let left;

  if (cleanCpf === null || cleanCpf.length < 11) return true;

  if (cleanCpf === "00000000000") return errorMessage;

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(cleanCpf.substring(i - 1, i)) * (11 - i);
  }
  left = (sum * 10) % 11;

  if (left === 10 || left === 11) left = 0;
  if (left != parseInt(cleanCpf.substring(9, 10))) return errorMessage;

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(cleanCpf.substring(i - 1, i)) * (12 - i);
  }
  left = (sum * 10) % 11;

  if (left === 10 || left === 11) left = 0;
  if (left != parseInt(cleanCpf.substring(10, 11))) return errorMessage;

  return true;
}

const invalidCpfMessage = "CPF inválido!";
const invalidCnpjMessage = "CNPJ inválido!";
const invalidCpfCnpjSizeMessage = "Tamanho inválido!";
export function validateCpfCnpj(value) {
  const cleanedInput = value
    .replaceAll(".", "")
    .replaceAll("/", "")
    .replaceAll("-", "");
  if (cleanedInput.length === 11) {
    let cpf = value.trim();

    cpf = cpf.replace(/\./g, "");
    cpf = cpf.replace("-", "");
    cpf = cpf.split("");

    let v1 = 0;
    let v2 = 0;
    let aux = false;

    for (let i = 1; cpf.length > i; i++) {
      if (cpf[i - 1] != cpf[i]) {
        aux = true;
      }
    }

    if (aux === false) {
      return invalidCpfMessage;
    }

    for (let i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
      v1 += cpf[i] * p;
    }

    v1 = (v1 * 10) % 11;

    if (v1 === 10) {
      v1 = 0;
    }

    if (v1 != cpf[9]) {
      return invalidCpfMessage;
    }

    for (let i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
      v2 += cpf[i] * p;
    }

    v2 = (v2 * 10) % 11;

    if (v2 === 10) {
      v2 = 0;
    }

    if (v2 != cpf[10]) {
      return invalidCpfMessage;
    } else {
      return true;
    }
  } else if (cleanedInput.length === 14) {
    let cnpj = value.trim();

    cnpj = cnpj.replace(/\./g, "");
    cnpj = cnpj.replace("-", "");
    cnpj = cnpj.replace("/", "");
    cnpj = cnpj.split("");

    let v1 = 0;
    let v2 = 0;
    let aux = false;

    for (let i = 1; cnpj.length > i; i++) {
      if (cnpj[i - 1] != cnpj[i]) {
        aux = true;
      }
    }

    if (aux === false) {
      return invalidCnpjMessage;
    }

    for (let i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v1 += cnpj[i] * p1;
      } else {
        v1 += cnpj[i] * p2;
      }
    }

    v1 = v1 % 11;

    if (v1 < 2) {
      v1 = 0;
    } else {
      v1 = 11 - v1;
    }

    if (v1 != cnpj[12]) {
      return invalidCnpjMessage;
    }

    for (let i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v2 += cnpj[i] * p1;
      } else {
        v2 += cnpj[i] * p2;
      }
    }

    v2 = v2 % 11;

    if (v2 < 2) {
      v2 = 0;
    } else {
      v2 = 11 - v2;
    }

    if (v2 != cnpj[13]) {
      return invalidCnpjMessage;
    } else {
      return true;
    }
  } else {
    return invalidCpfCnpjSizeMessage;
  }
}

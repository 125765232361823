import ExpeditionsPage from "./views/ExpeditionsPage";
import ExpeditionFormPage from "./views/ExpeditionFormPage";

export const expeditionsRoutesPaths = {
  expeditions: "/expeditions",
  createExpedition: "/expeditions/create",
  editExpedition: "/expeditions/:id"
};

export const expeditionsRoutes = [
  {
    path: expeditionsRoutesPaths.expeditions,
    name: "Expeditions",
    component: ExpeditionsPage
  },
  {
    path: expeditionsRoutesPaths.createExpedition,
    name: "Create Expedition",
    component: ExpeditionFormPage
  },
  {
    path: expeditionsRoutesPaths.editExpedition,
    name: "Edit Expedition",
    component: ExpeditionFormPage
  }
];

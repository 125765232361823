var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-holder',{attrs:{"breadCombsText":("Home - Usuários - " + (_vm.isNewUser ? 'Novo' : 'Editar'))}},[_c('v-card',{staticClass:"flex-grow-1"},[_c('v-col',[_c('v-row',{staticClass:"pl-8 pr-8 pa-4"},[_c('h3',[_vm._v(_vm._s(_vm.isNewUser ? "Novo" : "Editar")+" Usuário")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"outlined":"","color":"#8C8C8C"},on:{"click":function($event){return _vm.handleCancelButtom()}}},[_vm._v("CANCELAR")]),_c('v-btn',{staticClass:"ml-4",attrs:{"dark":"","color":"#303030"},on:{"click":function($event){return _vm.handleSaveButtom()}}},[_vm._v(" SALVAR ")])],1),_c('v-divider'),_c('v-row',{staticClass:"pl-8 pr-8 pa-4"},[_c('v-form',{ref:"form",staticClass:"flex-grow-1",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_c('v-text-field',{attrs:{"label":"Nome*","required":"","rules":[_vm.rules.required, _vm.rules.fieldErrors('nome')],"hint":"ex.\n              Liz Sophia Teixeira","persistent-hint":""},model:{value:(_vm.user.nome),callback:function ($$v) {_vm.$set(_vm.user, "nome", $$v)},expression:"user.nome"}})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('v-text-field',{attrs:{"label":"Email*","required":"","rules":[
                  _vm.rules.required,
                  _vm.rules.validEmail,
                  _vm.rules.fieldErrors('email')
                ],"hint":"ex.\n              lizsophiateixeira_@obrativaengenharia.com.br","persistent-hint":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"3"}},[_c('v-select',{attrs:{"items":_vm.states,"item-text":"state","item-value":"abbr","label":"Estado*","rules":[_vm.rules.required, _vm.rules.fieldErrors('endereco.uf')],"single-line":""},model:{value:(_vm.user.endereco.uf),callback:function ($$v) {_vm.$set(_vm.user.endereco, "uf", $$v)},expression:"user.endereco.uf"}})],1),_c('v-col',{attrs:{"lg":"3"}},[_c('v-text-field',{attrs:{"label":"Missão (Cidade)*","required":"","rules":[
                  _vm.rules.required,
                  _vm.rules.fieldErrors('endereco.missao')
                ],"hint":"ex. Fortaleza","persistent-hint":""},model:{value:(_vm.user.endereco.missao),callback:function ($$v) {_vm.$set(_vm.user.endereco, "missao", $$v)},expression:"user.endereco.missao"}})],1),_c('v-col',{attrs:{"lg":"3"}},[_c('v-text-field',{attrs:{"label":"Contato 1*","required":"","rules":[
                  _vm.rules.required,
                  _vm.rules.fieldErrors('contato.contato1'),
                  _vm.rules.minLengthRequired(10)
                ],"hint":"ex. (85)1234-7894","persistent-hint":""},model:{value:(_vm.user.contato.contato1),callback:function ($$v) {_vm.$set(_vm.user.contato, "contato1", $$v)},expression:"user.contato.contato1"}})],1),_c('v-col',{attrs:{"lg":"3"}},[_c('v-text-field',{attrs:{"label":"Contato 2","rules":[
                  _vm.rules.fieldErrors('contato.contato2'),
                  _vm.rules.minLengthRequired(10)
                ],"hint":"ex. (85)1234-7894","persistent-hint":""},model:{value:(_vm.user.contato.contato2),callback:function ($$v) {_vm.$set(_vm.user.contato, "contato2", $$v)},expression:"user.contato.contato2"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"3"}},[_c('v-text-field',{attrs:{"label":"Login*","required":"","readonly":!_vm.isNewUser,"rules":[_vm.rules.required, _vm.rules.fieldErrors('login')],"hint":_vm.isNewUser ? 'ex. maria_clara' : '',"persistent-hint":""},model:{value:(_vm.user.login),callback:function ($$v) {_vm.$set(_vm.user, "login", $$v)},expression:"user.login"}})],1),_c('v-col',{attrs:{"lg":"3"}},[_c('v-text-field',{attrs:{"label":_vm.isNewUser ? 'Senha*' : 'Senha',"required":"","rules":[
                  _vm.rules.ifCreateIsRequired,
                  _vm.rules.fieldErrors('senha'),
                  _vm.rules.minLengthRequired(4)
                ],"persistent-hint":"","type":"password"},model:{value:(_vm.user.senha),callback:function ($$v) {_vm.$set(_vm.user, "senha", $$v)},expression:"user.senha"}})],1),_c('v-col',{attrs:{"lg":"3"}},[_c('v-text-field',{attrs:{"label":_vm.isNewUser ? 'Confirmação de senha*' : 'Confirmação de senha',"required":"","rules":[
                  _vm.rules.ifCreateIsRequired,
                  _vm.rules.matchPasswordFieldValue,
                  _vm.rules.fieldErrors('confirmacaoSenha')
                ],"persistent-hint":"","type":"password"},model:{value:(_vm.user.confirmacaoSenha),callback:function ($$v) {_vm.$set(_vm.user, "confirmacaoSenha", $$v)},expression:"user.confirmacaoSenha"}})],1),_c('v-col',{attrs:{"lg":"3"}},[_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.user.perfil === _vm.userTypes.operator),expression:"user.perfil === userTypes.operator"}],attrs:{"items":_vm.expeditions,"item-text":"nome","item-value":"id","label":"Expedição*","readonly":!_vm.isNewUser,"rules":[
                  _vm.rules.ifIsOperatorIsRequired,
                  _vm.rules.fieldErrors('idRegistro')
                ],"single-line":""},model:{value:(_vm.user.idRegistro),callback:function ($$v) {_vm.$set(_vm.user, "idRegistro", $$v)},expression:"user.idRegistro"}})],1)],1),(_vm.isAdmin)?_c('v-row',[_c('v-col',[_c('p',{staticClass:"mb-0"},[_vm._v("Acesso do Perfil*")]),_c('v-radio-group',{staticClass:"mt-1",attrs:{"mandatory":"","row":"","readonly":!_vm.isNewUser,"rules":[_vm.rules.required, _vm.rules.fieldErrors('perfil')]},model:{value:(_vm.user.perfil),callback:function ($$v) {_vm.$set(_vm.user, "perfil", $$v)},expression:"user.perfil"}},[_c('v-radio',{attrs:{"label":"Operador expedição","value":_vm.userTypes.operator}}),_c('v-radio',{attrs:{"label":"Administrador","value":_vm.userTypes.admin},on:{"click":function($event){return _vm.removeExpedition()}}})],1)],1)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
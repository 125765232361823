import { getRequestIdAndIncrementCounter } from "../../service/service_store";
import { clearState } from "../../utils/vuex";
const initialState = {
  carriers: [],
  carriersCount: 0
};
export const carriersStore = {
  state: () => ({ ...initialState }),
  getters: {
    getCarrier: state => id => state.carriers.find(c => c.id == id)
  },
  mutations: {
    setCarriers(state, { carriers, carriersCount }) {
      state.carriers = carriers;
      state.carriersCount = carriersCount;
    },
    clearCarrierStore(state) {
      clearState(state, initialState);
    }
  },
  actions: {
    async requestCarriers(
      { dispatch, commit, getters },
      { page, pageSize, nameFilter }
    ) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });
      const isPaginatedRequest = pageSize !== -1 && !nameFilter;

      const route = isPaginatedRequest
        ? `/transportadora/page?pagina=${page}&quantidade=${pageSize}`
        : nameFilter
        ? "/transportadora/nome?nome=" + nameFilter
        : "/transportadora";

      await dispatch("requestWithAuth", {
        requestId,
        route,
        additionalRequestInit: {
          method: "GET"
        }
      });

      const request = getters.getRequests[requestId];

      if (request.ok) {
        const carriers = isPaginatedRequest ? request.body.list : request.body;
        const carriersCount = isPaginatedRequest
          ? request.body.totalItems
          : request.body.length;

        commit("setCarriers", { carriers, carriersCount });
      }

      commit("deleteRequest", requestId);
    },
    async submitCarrier({ commit, dispatch, getters }, carrier) {
      const isNewCarrier = !carrier.id;

      const cleanedCarrier = { ...carrier };
      if (!cleanedCarrier.usuario1.senha) {
        cleanedCarrier.usuario1 = undefined;
      }
      if (!cleanedCarrier.usuario2.senha) {
        cleanedCarrier.usuario2 = undefined;
      }

      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = `/transportadora/${cleanedCarrier.id || ""}`;
      await dispatch("requestWithAuth", {
        requestId,
        route,
        additionalRequestInit: {
          method: isNewCarrier ? "POST" : "PUT",
          body: cleanedCarrier
        }
      });

      const request = getters.getRequests[requestId];

      if (request.ok) {
        commit("navigateBack");
      } else {
        dispatch("displayAlert", {
          message: request.body.message,
          type: "error"
        });
      }

      commit("deleteRequest", requestId);
    },
    async deleteCarrier({ commit, dispatch, getters }, carrier) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = `/transportadora/${carrier.id}`;
      await dispatch("requestWithAuth", {
        requestId,
        route,
        additionalRequestInit: {
          method: "DELETE"
        }
      });

      const request = getters.getRequests[requestId];

      if (request.ok) {
        dispatch("requestCarriers", { page: 0, pageSize: -1 });
      } else {
        dispatch("displayAlert", {
          message: request.body.message,
          type: "error"
        });
      }

      commit("deleteRequest", requestId);
    }
  }
};

<template>
  <v-dialog v-model="isVisible" max-width="60vw">
    <v-card>
      <v-card-title>
        Transporte
      </v-card-title>
      <v-container>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col lg="4.5">
                <v-select
                  :items="carriersList"
                  label="Transportadora"
                  v-model="selectedCarrier"
                  item-text="nome"
                  item-value="id"
                  :rules="[rules.required]"
                  return-object
                  single-line
                />
              </v-col>
              <v-col lg="4.5" v-if="agentsList">
                <v-select
                  :items="agentsList"
                  label="Agente"
                  v-model="selectedAgent"
                  item-text="usuario.nome"
                  item-value="usuario.id"
                  return-object
                  single-line
                  no-data-text="Selecione uma transportadora primeiro"
                />
              </v-col>
              <v-col lg="3">
                <v-text-field
                  label="Valor do transporte"
                  prefix="R$"
                  v-model="shippingFee"
                  :rules="[rules.validPrice]"
                  :readonly="isCarrier"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="ma-auto mr-2"
            outlined
            color="#8C8C8C"
            @click="$emit('close-shipping-dialog')"
            >Cancelar</v-btn
          >
          <v-btn dark color="#303030" class="ma-2" @click="onClickSendButton()">
            Enviar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { required, validPrice } from "@/utils/formFieldRules";
import { userTypes } from "@/modules/users/utils";

export default {
  props: {
    isDialogVisible: Boolean,
    order: Object
  },

  data: () => ({
    shippingFee: null,
    isFormValid: false,
    isVisible: false,
    selectedAgent: null,
    selectedCarrier: null
  }),

  computed: {
    agentsList() {
      if (this.selectedCarrier) {
        return this.$store.state.ordersStore.agents;
      } else {
        return [];
      }
    },

    carriersList() {
      if (this.isCarrier) {
        return [this.order.transportadora];
      } else {
        return this.$store.state.ordersStore.carriers;
      }
    },

    isCarrier() {
      const userType = this.$store.state.authStore.userType;
      return userType === userTypes.carrier;
    },

    rules() {
      return {
        required,
        validPrice
      };
    }
  },

  watch: {
    isDialogVisible(newValue) {
      this.isVisible = newValue;
    },

    isVisible(newValue) {
      if (!newValue) {
        this.selectedCarrier = null;
        this.selectedAgent = null;
        this.shippingFee = null;
        this.$emit("close-shipping-dialog");
      } else {
        this.shippingFee = this.order.precoTransporte.slice(3);

        if (this.order.transportadora && this.order.transportadora.id) {
          this.selectedCarrier = this.order.transportadora;
        }
      }
    },

    agentsList() {
      this.selectedAgent = null;
    },

    selectedCarrier(newValue) {
      if (newValue) {
        this.$store
          .dispatch("requestAgentsList", {
            carrierId: this.selectedCarrier.id
          })
          .then(() => {
            if (
              JSON.stringify(newValue) ===
                JSON.stringify(this.order.transportadora) &&
              this.order.agente
            ) {
              this.selectedAgent = this.order.agente;
            }
          });
      }
    }
  },

  methods: {
    onClickSendButton() {
      let shipping = {};

      if (this.selectedCarrier) {
        shipping["orderId"] = this.order.id;
        shipping["carrierId"] = this.selectedCarrier.id;

        if (this.selectedAgent) {
          shipping["agentId"] = this.selectedAgent.usuario.id;
        }
        if (this.shippingFee) {
          shipping["shippingFee"] = this.shippingFee;
        }

        this.$store.dispatch("updateShipping", shipping).then(() => {
          this.$emit("save-shipping-update");
        });
      } else {
        this.$store.dispatch("displayAlert", {
          message: "Selecione uma transportadora.",
          type: "error"
        });
      }
    }
  }
};
</script>

<template>
  <page-holder breadCombsText="Início - Dashboard">
    <v-row>
      <v-col lg="6">
        <date-picker label="Data de Início" v-model="startDate" />
      </v-col>
      <v-col lg="6">
        <date-picker
          label="Data de Fim"
          v-model="endDate"
          :allowedDates="allowedEndDates"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" class="d-flex">
        <v-card elevation="2" class="pa-2 flex-grow-1 d-flex align-center">
          <v-row>
            <v-col>
              <v-card-subtitle class="pa-0 pl-1">
                Pedidos na Expedição
              </v-card-subtitle>
              <v-card-title class="pa-0 pl-1">
                {{
                  totalsByStatus.qtdPedidoExpedicao !== undefined
                    ? totalsByStatus.qtdPedidoExpedicao
                    : "-"
                }}
              </v-card-title>
            </v-col>
            <v-col class="flex-grow-0 d-flex align-center">
              <div class="icon-circle-container in-expedition-background">
                <v-icon dark> mdi-package-variant-closed </v-icon>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="3" class="d-flex">
        <v-card elevation="2" class="pa-2 flex-grow-1 d-flex align-center">
          <v-row>
            <v-col>
              <v-card-subtitle class="pa-0 pl-1">
                Pedidos na Transportadora
              </v-card-subtitle>
              <v-card-title class="pa-0 pl-1">
                {{
                  totalsByStatus.qtdPedidoTransportadora !== undefined
                    ? totalsByStatus.qtdPedidoTransportadora
                    : "-"
                }}
              </v-card-title>
            </v-col>
            <v-col class="flex-grow-0 d-flex align-center">
              <div class="icon-circle-container with-carrier-background">
                <v-icon dark> mdi-store-outline </v-icon>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="3" class="d-flex">
        <v-card elevation="2" class="pa-2 flex-grow-1 d-flex align-center">
          <v-row>
            <v-col>
              <v-card-subtitle class="pa-0 pl-1">
                Pedidos Entregues
              </v-card-subtitle>
              <v-card-title class="pa-0 pl-1">
                {{
                  totalsByStatus.qtdPedidoEntregue !== undefined
                    ? totalsByStatus.qtdPedidoEntregue
                    : "-"
                }}
              </v-card-title>
            </v-col>
            <v-col class="flex-grow-0 d-flex align-center">
              <div class="icon-circle-container delivered-background">
                <v-icon dark> mdi-check </v-icon>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="3" class="d-flex">
        <v-card elevation="2" class="pa-2 flex-grow-1 d-flex align-center">
          <v-row>
            <v-col>
              <v-card-subtitle class="pa-0 pl-1">
                Pedidos Frustrados
              </v-card-subtitle>
              <v-card-title class="pa-0 pl-1">
                {{
                  totalsByStatus.qtdPedidoFrustrado !== undefined
                    ? totalsByStatus.qtdPedidoFrustrado
                    : "-"
                }}
              </v-card-title>
            </v-col>
            <v-col class="flex-grow-0 d-flex align-center">
              <div class="icon-circle-container delayed-background">
                <v-icon dark> mdi-close </v-icon>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="6" class="align-stretch">
        <v-card class="pa-5">
          <v-row>
            <v-col lg="6" class="align-center d-flex">
              <h4 class="ml-1">
                Pedidos por localização
              </h4>
            </v-col>

            <v-col lg="6" v-if="!isCarrier" style="opacity: 0">
              <v-text-field />
            </v-col>
          </v-row>
          <div class="chart-container">
            <apexchart
              type="bar"
              :options="ordersByLocationData.chartOptions"
              :series="ordersByLocationData.series"
            />
          </div>
        </v-card>
      </v-col>
      <v-col lg="6">
        <v-card class="pa-5">
          <v-row>
            <v-col lg="6" class="align-center d-flex">
              <h4 class="ml-1">
                Pedidos por transportadora
              </h4>
            </v-col>

            <v-col lg="6" v-if="!isCarrier">
              <v-text-field
                label="Filtrar por transportadora"
                persistent-hint
                hint="ex. Correios"
                v-model="carrierFilter"
              />
            </v-col>
          </v-row>
          <div class="chart-container">
            <apexchart
              type="bar"
              :options="ordersByCarrierData.chartOptions"
              :series="ordersByCarrierData.series"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-row>
              <v-col lg="8" class="align-center d-flex">
                <h4 class="ml-1">
                  Envios por estado
                </h4>
              </v-col>

              <v-col lg="4">
                <v-text-field
                  label="Filtrar por estado"
                  persistent-hint
                  hint="ex. CE"
                  v-model="stateFilter"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-row>
              <v-col lg="6" class="map-container">
                <l-map :zoom="3.75" :center="mapCenter" :options="mapOptions">
                  <l-tile-layer :url="mapUrl" :attribution="mapAttribution" />
                  <l-marker
                    v-for="(l, index) in statePositions"
                    :key="index"
                    :lat-lng="l"
                  />
                </l-map>
              </v-col>
              <v-col lg="6">
                <v-data-table
                  class="elevation-1"
                  dense
                  :items="statesData"
                  :headers="statesTableHeaders"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!isCarrier">
      <v-col lg="6">
        <v-card class="pa-5">
          <v-card-subtitle class="pa-0 pl-1"> Performance </v-card-subtitle>

          <v-row class="pa-0 pl-1">
            <v-col>
              <h4>Valor total por transportadora</h4>
            </v-col>
            <v-col style="opacity: 0">
              <v-text-field class="ma-0 ml-1" />
            </v-col>
          </v-row>

          <div class="chart-container">
            <apexchart
              type="bar"
              :options="deliveryValueByCarrierData.options"
              :series="deliveryValueByCarrierData.series"
            />
          </div>
        </v-card>
      </v-col>
      <v-col lg="6">
        <v-card class="pa-5">
          <v-card-subtitle class="pa-0 pl-1"> Performance </v-card-subtitle>

          <v-row class="pa-0 pl-1">
            <v-col>
              <h4>Valor total por expedição</h4>
            </v-col>
            <v-col>
              <v-text-field
                label="Filtrar por expedição"
                persistent-hint
                hint="ex. Correios"
                class="ma-0 ml-1"
                v-model="expeditionFilter"
              />
            </v-col>
          </v-row>

          <div class="chart-container">
            <apexchart
              type="bar"
              :options="deliveryValueByExpeditionData.options"
              :series="deliveryValueByExpeditionData.series"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="!isCarrier">
      <v-col lg="6">
        <v-card class="pa-5">
          <v-card-subtitle class="pa-0 pl-1"> Performance </v-card-subtitle>

          <v-row class="pa-0 pl-1">
            <v-col>
              <h4>Preço total de transporte por transportadora</h4>
            </v-col>
            <v-col>
              <v-text-field
                label="Filtrar por transportadora"
                persistent-hint
                hint="ex. Correios"
                class="ma-0 ml-1"
                v-model="carrierShippingFeeFilter"
              />
            </v-col>
          </v-row>

          <div class="chart-container">
            <apexchart
              type="bar"
              :options="shippingFeeValueByCarrierData.options"
              :series="shippingFeeValueByCarrierData.series"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="!isCarrier">
      <v-col>
        <v-card>
          <v-card-title>
            <v-row>
              <v-col lg="6" class="align-center d-flex">
                <h4 class="ml-1">
                  Vendas por vendedor
                </h4>
              </v-col>

              <v-col lg="6">
                <v-text-field
                  label="Pesquisar por vendedor ou expedição"
                  persistent-hint
                  hint="ex. José ou Diaconia"
                  v-model="salesmenFilter"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <div class="sales-grid-border">
              <div class="sales-grid">
                <div
                  v-for="(s, index) in salesmenData"
                  :key="index"
                  class="pa-4 pb-2 pt-2"
                >
                  <v-row no-gutters class="mb-1">
                    <v-col>
                      <h4 class="mr-4">
                        {{ s.nome }}
                      </h4>
                    </v-col>
                    <v-col class="flex-grow-0">
                      <h4 class="max-content">
                        {{ formatMoneyValue(s.valor) }}
                      </h4>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-spacer />
                    <v-col class="flex-grow-0">
                      <h5 class="max-content">Expedição: {{ s.expedicao }}</h5>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </page-holder>
</template>

<script>
import { latLng } from "leaflet";
import { userTypes } from "../users/utils";
import { formatMoneyValue } from "../../utils/misc";
export default {
  mounted() {
    this.requestData();
    this.refreshInterval = setInterval(() => {
      this.requestData();
    }, 60000);
  },
  beforeDestroy() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = undefined;
    }
  },
  data() {
    const lastDayOfMonth = new Date();
    lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1);
    lastDayOfMonth.setDate(0);

    const firstDayOfMonth = new Date();
    firstDayOfMonth.setDate(1);
    return {
      startDate: firstDayOfMonth.toISOString().slice(0, 10),
      endDate: lastDayOfMonth.toISOString().slice(0, 10),

      carrierFilter: "",
      carrierShippingFeeFilter: "",
      expeditionFilter: "",
      salesmenFilter: "",
      stateFilter: "",

      mapCenter: latLng(-16, -50),
      mapOptions: {
        zoomSnap: 0.25
      },
      mapUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      mapAttribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',

      statesTableHeaders: [
        { text: "Estado", value: "estado" },
        { text: "Número de pedidos", value: "total" }
      ],

      refreshInterval: undefined
    };
  },
  methods: {
    allowedEndDates(date) {
      const start = new Date(this.startDate.replaceAll("-", " "));
      const end = new Date(date.replaceAll("-", " "));
      return start < end;
    },
    requestData() {
      [
        "requestTotalsByStatus",
        "requestByLocationData",
        "requestByCarriersData",
        "requestStatesData",
        "requestDeliveryValueByExpedition",
        "requestDeliveryValueByCarrier",
        "requestSalesmen",
        "requestShippingFee"
      ].forEach(actionType => {
        this.$store.dispatch(actionType, {
          startDate: this.startDate,
          endDate: this.endDate
        });
      });
    },
    formatDashboardAgrupadoDTO(data) {
      const apiData = data ?? [];
      return {
        series: [
          {
            name: "Na Expedição",
            data: apiData.map(e => e.qtdPedidoExpedicao || 0)
          },
          {
            name: "Na Transportadora",
            data: apiData.map(e => e.qtdPedidoTransportadora || 0)
          },
          {
            name: "Entregues",
            data: apiData.map(e => e.qtdPedidoEntregue || 0)
          }
        ],
        chartOptions: {
          colors: ["#004B93", "#F5CF00", "#2DCE98"],
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: apiData.map(e => e.nome)
          }
        }
      };
    },
    setRefreshInterval() {
      clearInterval(this.refreshInterval);
      this.refreshInterval = setInterval(() => {
        this.requestData();
      }, 60000);
    },
    formatMoneyValue
  },
  watch: {
    startDate() {
      this.setRefreshInterval();
      this.requestData();
    },
    endDate() {
      this.setRefreshInterval();
      this.requestData();
    },
    carrierFilter() {
      this.setRefreshInterval();
    },
    expeditionFilter() {
      this.setRefreshInterval();
    },
    salesmenFilter() {
      this.setRefreshInterval();
    },
    stateFilter() {
      this.setRefreshInterval();
    }
  },
  computed: {
    isCarrier() {
      const userType = this.$store.state.authStore.userType;
      return userType === userTypes.carrier;
    },

    totalsByStatus() {
      return this.$store.state.dashboardStore.totalsByStatus;
    },

    ordersByLocationData() {
      return this.formatDashboardAgrupadoDTO(
        this.$store.state.dashboardStore.byLocationData
      );
    },

    ordersByCarrierData() {
      let apiData = this.$store.state.dashboardStore.byCarriersData;
      if (this.carrierFilter) {
        apiData = apiData.filter(data =>
          data.nome.toLowerCase().includes(this.carrierFilter.toLowerCase())
        );
      }
      return this.formatDashboardAgrupadoDTO(apiData);
    },

    statesData() {
      let apiData = this.$store.state.dashboardStore.statesData;
      if (this.stateFilter) {
        apiData = apiData.filter(data =>
          data.estado.toLowerCase().includes(this.stateFilter.toLowerCase())
        );
      }

      return apiData ?? [];
    },

    deliveryValueByCarrierData() {
      const apiData = this.$store.state.dashboardStore.deliveryValueByCarrier;
      return {
        series: [
          {
            name: "Valor",
            data: apiData.map(e => e.valor)
          }
        ],
        options: {
          colors: ["#F5CF00"],
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: apiData.map(e => e.transportadora)
          },
          yaxis: {
            labels: {
              formatter: value => this.formatMoneyValue(value)
            }
          }
        }
      };
    },

    deliveryValueByExpeditionData() {
      let apiData = this.$store.state.dashboardStore.deliveryValueByExpedition;

      if (this.expeditionFilter) {
        apiData = apiData.filter(data =>
          data.expedicao
            .toLowerCase()
            .includes(this.expeditionFilter.toLowerCase())
        );
      }

      return {
        series: [
          {
            name: "Valor",
            data: apiData.map(e => e.valor)
          }
        ],
        options: {
          colors: ["#F5CF00"],
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: apiData.map(e => e.expedicao)
          },
          yaxis: {
            labels: {
              formatter: value => this.formatMoneyValue(value)
            }
          }
        }
      };
    },

    shippingFeeValueByCarrierData() {
      let apiData = this.$store.state.dashboardStore.shippingFee;

      if (this.carrierShippingFeeFilter) {
        apiData = apiData.filter(data =>
          data.transportadora
            .toLowerCase()
            .includes(this.carrierShippingFeeFilter.toLowerCase())
        );
      }

      return {
        series: [
          {
            name: "Valor",
            data: apiData.map(e => e.valor)
          }
        ],
        options: {
          colors: ["#F5CF00"],
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: apiData.map(e => e.transportadora)
          },
          yaxis: {
            labels: {
              formatter: value => this.formatMoneyValue(value)
            }
          }
        }
      };
    },

    salesmenData() {
      let apiData = this.$store.state.dashboardStore.salesmen;
      if (this.salesmenFilter) {
        apiData = apiData.filter(
          data =>
            data.nome
              .toLowerCase()
              .includes(this.salesmenFilter.toLowerCase()) ||
            data.expedicao
              .toLowerCase()
              .includes(this.salesmenFilter.toLowerCase())
        );
      }
      return apiData;
    },

    statePositions() {
      const allStatePositions = {
        AC: [-8.77, -70.55],
        AL: [-9.71, -35.73],
        AM: [-3.07, -61.66],
        AP: [1.41, -51.77],
        BA: [-12.96, -38.51],
        CE: [-3.71, -38.54],
        DF: [-15.83, -47.86],
        ES: [-19.19, -40.34],
        GO: [-16.64, -49.31],
        MA: [-2.55, -44.3],
        MT: [-12.64, -55.42],
        MS: [-20.51, -54.54],
        MG: [-18.1, -44.38],
        PA: [-5.53, -52.29],
        PB: [-7.06, -35.55],
        PR: [-24.89, -51.55],
        PE: [-8.28, -35.07],
        PI: [-8.28, -43.68],
        RJ: [-22.84, -43.15],
        RN: [-5.22, -36.52],
        RO: [-11.22, -62.8],
        RS: [-30.01, -51.22],
        RR: [1.89, -61.22],
        SC: [-27.33, -49.44],
        SE: [-10.9, -37.07],
        SP: [-23.55, -46.64],
        TO: [-10.25, -48.25]
      };

      let statePositions = [];
      for (let state of this.statesData) {
        statePositions.push(allStatePositions[state.estado]);
      }
      return statePositions;
    }
  }
};
</script>

<style>
.icon-circle-container {
  border-radius: 100%;
  width: max-content;
  height: max-content;
  padding: 4px;
}
.in-expedition-background {
  background-color: #004b93;
}
.with-carrier-background {
  background-color: #f5cf00;
}
.delivered-background {
  background-color: #2dce98;
}
.delayed-background {
  background-color: #f53c56;
}
.apexcharts-toolbar {
  z-index: 8 !important;
}
.chart-container {
  overflow: auto;
  overflow-y: hidden;
}
.map-container {
  height: 30rem;
}
.sales-grid-border {
  background-color: rgba(0, 0, 0, 0.12);
}
.sales-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1px;
}
.sales-grid > div {
  background-color: white;
}
.sales-grid h4 {
  color: #172b4d;
}
.max-content {
  min-width: max-content;
}
.sales-grid h5 {
  color: #b7c1cc;
  text-transform: uppercase;
}
.sales-grid .row {
  margin: 0;
}
</style>

import LoginPage from "./views/LoginPage.vue";
import ForgotPasswordPage from "./views/ForgotPasswordPage.vue";
import NewPasswordPage from "./views/NewPasswordPage.vue";

export const authRoutesPaths = {
  login: "/login",
  forgotPassword: "/forgot-password",
  newPassword: "/new-password"
};

export const authRoutes = [
  {
    path: authRoutesPaths.login,
    name: "Login",
    component: LoginPage
  },
  {
    path: authRoutesPaths.forgotPassword,
    name: "ForgotPassword",
    component: ForgotPasswordPage
  },
  {
    path: authRoutesPaths.newPassword,
    name: "NewPassword",
    component: NewPasswordPage
  }
];

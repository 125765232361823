import Vue from "vue";
import VueRouter from "vue-router";

import { authRoutes } from "../modules/auth/routes";
import { usersRoutes } from "../modules/users/routes";
import { expeditionsRoutes } from "../modules/expeditions/routes";
import { carriersRoutes } from "../modules/carrier/routes";
import { deliverymenRoutes } from "../modules/deliverymen/routes";
import { ordersRoutes } from "../modules/orders/routes";
import { dashboardRoutes } from "../modules/dashboard/routes";

Vue.use(VueRouter);

const routes = [
  ...authRoutes,
  ...usersRoutes,
  ...expeditionsRoutes,
  ...carriersRoutes,
  ...deliverymenRoutes,
  ...ordersRoutes,
  ...dashboardRoutes
];

const router = new VueRouter({
  routes,
  mode: "history"
});

export default router;

<template>
  <page-holder>
    <v-form v-model="isSearchFormValid" ref="searchForm">
      <v-row>
        <v-col class="flex-grow-1">
          <v-text-field
            v-model="barcode"
            label="Informe o código ou leia o código de barras para adicionar o pedido"
            hint="Ex. 5168651351684613598"
            :rules="[rules.required]"
            persistent-hint
          />
        </v-col>
        <v-col lg="3" v-if="isUserAdmin">
          <v-select
            v-model="selectedExpedition"
            :items="expeditions"
            :rules="[rules.requiredIfAdmin]"
            item-text="nome"
            item-value="id"
          />
        </v-col>
        <v-col class="flex-grow-0 align-center d-flex">
          <v-btn outlined @click="addOrder()"> Adicionar </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-row v-if="searchedOrder !== null">
      <v-col>
        <v-card>
          <v-tabs v-model="tab" class="flex-grow-1 justify-center d-flex mt-8">
            <v-tab key="generalData"> Dados Gerais </v-tab>
            <v-tab key="products">Produtos</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="flex-grow-1 mt-2 pa-6">
            <v-tab-item key="generalData">
              <v-row>
                <v-col lg="6">
                  <v-text-field
                    label="Nome do Cliente"
                    readonly
                    v-model="searchedOrder.nomeCliente"
                  />
                </v-col>
                <v-col lg="6">
                  <v-text-field
                    label="Contato"
                    readonly
                    v-model="searchedOrder.contatoTelefone"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="2">
                  <v-text-field
                    label="Estado"
                    readonly
                    v-model="searchedOrder.nomeEstadoCliente"
                  />
                </v-col>
                <v-col lg="4">
                  <v-text-field
                    label="Cidade"
                    readonly
                    v-model="searchedOrder.nomeCidadeCliente"
                  />
                </v-col>
                <v-col lg="4">
                  <v-text-field
                    label="Bairro"
                    readonly
                    v-model="searchedOrder.bairroClienteEnd"
                  />
                </v-col>
                <v-col lg="2">
                  <v-text-field
                    label="CEP"
                    readonly
                    v-mask="'#####-###'"
                    v-model="searchedOrder.cepClienteEnd"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="12">
                  <v-text-field
                    label="Endereço"
                    readonly
                    v-model="clientAddress"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item key="products">
              <v-row>
                <v-spacer class="flex-grow-1" />
                <v-col class="flex-grow-0">
                  <v-btn dark color="#303030" @click="onClickShippingButton()">
                    Entrega
                  </v-btn>
                </v-col>
              </v-row>
              <v-data-table
                :headers="productHeaders"
                :items="products"
                :items-per-page="5"
                class="elevation-1"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <ShippingFormDialog
      :isDialogVisible="isShippingDialogVisible"
      :order="searchedOrder"
      @close-shipping-dialog="onCloseShippingDialog()"
      @save-shipping-update="onCloseShippingDialog()"
    ></ShippingFormDialog>
  </page-holder>
</template>

<script>
import immutable from "immutable";
import {
  required,
  requiredIfConditionMatch
} from "../../../utils/formFieldRules";
import { formatMoneyValue } from "../../../utils/misc";
import { userTypes } from "../../users/utils";
import ShippingFormDialog from "../components/ShippingFormDialog";

export default {
  components: {
    ShippingFormDialog
  },
  async mounted() {
    if (this.isUserAdmin) {
      await this.$store.dispatch("requestExpeditions", {
        page: 0,
        pageSize: -1
      });
      this.selectedExpedition = this.expeditions[0].id;
    }
  },
  data: () => ({
    tab: null,
    selectedExpedition: null,
    isSearchFormValid: false,
    isShippingDialogVisible: false,
    barcode: null,
    productHeaders: [
      { text: "Descrição", value: "descricao" },
      { text: "Quantidade", value: "quantidade" }
    ]
  }),
  computed: {
    expeditions() {
      return this.$store.state.expeditionsStore.expeditions;
    },
    isUserAdmin() {
      return this.$store.state.authStore.userType === userTypes.admin;
    },
    rules() {
      return {
        required,
        requiredIfAdmin: requiredIfConditionMatch(this.isUserAdmin)
      };
    },
    searchedOrder() {
      return this.$store.state.ordersStore.searchedOrder;
    },
    clientAddress() {
      return `${this.searchedOrder.nomeLogradouroCliente}, ${this.searchedOrder.numeroCliente}`;
    },
    products() {
      return this.searchedOrder.itemPedidos.map(i =>
        immutable
          .Map(i)
          .set("price", formatMoneyValue(i.valorUnitario))
          .toJS()
      );
    }
  },
  methods: {
    onCloseShippingDialog() {
      this.isShippingDialogVisible = false;
    },

    onClickShippingButton() {
      this.isShippingDialogVisible = true;
      this.getCarriers();
    },

    addOrder() {
      if (this.isSearchFormValid) {
        this.completeBarCodeLength();

        this.$store.dispatch("requestSearchOrder", {
          barcode: this.barcode,
          expeditionId: this.selectedExpedition
        });
      } else {
        this.$refs.searchForm.validate();
      }
    },

    completeBarCodeLength() {
      if (this.barcode.length < 9) {
        const zeros = "0".repeat(9 - this.barcode.length);
        this.barcode = zeros + this.barcode;
      }
    },

    getCarriers() {
      this.$store.dispatch("requestCarriersList");
    }
  }
};
</script>

<style></style>

<template>
  <v-dialog v-model="shouldDisplayDialog" width="unset">
    <v-card>
      <v-card-title class="justify-center">
        {{ question }}
      </v-card-title>

      <v-card-actions>
        <v-btn
          class="ma-auto mr-2"
          outlined
          color="#8C8C8C"
          @click="closeDialog()"
          >Cancelar</v-btn
        >
        <v-btn dark color="#303030" class="ma-2" @click="handleClickConfirm()">
          {{ confirmButtomLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    shouldDisplayDialog: false
  }),
  props: {
    question: String,
    confirmButtomLabel: String,
    handleConfirm: Function,
    cancelMessage: String
  },
  methods: {
    closeDialog() {
      if (this.cancelMessage) {
        this.$store.dispatch("displayAlert", {
          message: this.cancelMessage,
          type: "warning"
        });
      }
      this.shouldDisplayDialog = false;
    },
    handleClickConfirm() {
      this.handleConfirm();
      this.shouldDisplayDialog = false;
    },
    openDialog() {
      this.shouldDisplayDialog = true;
    }
  }
};
</script>

<style></style>

import immutable from "immutable";

const isDevelopmentEnv = process.env.NODE_ENV === "development";

/* const BASIC_REQUEST_INIT = Object.assign(
  { headers: { "Content-Type": "application/json" } },
  isDevelopmentEnv ? { mode: "cors" } : { mode: "no-cors" }
); */

const BASIC_REQUEST_INIT = Object.assign(
  { headers: { "Content-Type": "application/json" } },
  isDevelopmentEnv ? { mode: "cors" } : { mode: "cors" }
);

export function createRequestUrl(route) {
  //return (process.env.VUE_APP_API_URL || "http://wda.hopto.org:8069") + route;
  //return (process.env.VUE_APP_API_URL || "http://3.15.194.227:8069") + route;
  // return (process.env.VUE_APP_API_URL || "http://localhost:8080") + route;
  return (process.env.VUE_APP_API_URL || "https://t2.shalom.tec.br/shalom-logistica") + route;
}

// Adiciona alguns parâmetros padrão para o serviço e serializa o body se houver algum
export function createRequestInit(additionalRequestInit) {
  if (additionalRequestInit.body) {
    additionalRequestInit.body = JSON.stringify(additionalRequestInit.body);
  }
  return immutable
    .mergeDeep(
      immutable.Map(BASIC_REQUEST_INIT),
      immutable.Map(additionalRequestInit)
    )
    .toJS();
}

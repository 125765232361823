<template>
  <div class="holder">
    <input @change="changeInput" ref="photoInput" type="file" hidden />
    <v-btn
      elevation="2"
      v-show="!value"
      outlined
      :class="textClass"
      @click="selectPhoto()"
      :width="size || '10rem'"
      :height="size || '10rem'"
    >
      {{ label }}
    </v-btn>
    <v-img
      v-show="!!value"
      ref="imgHolder"
      @click="selectPhoto()"
      :src="value"
      :max-width="size || '10rem'"
      :max-height="size || '10rem'"
    >
      <p class="label pa-4">
        {{ label }}
      </p>
    </v-img>
  </div>
</template>

<script>
export default {
  props: ["value", "label", "size", "textClass"],
  methods: {
    selectPhoto() {
      this.$refs.photoInput.click();
      this.$emit("input", null);
    },
    changeInput(input) {
      if (input.target.files && input.target.files.length > 0) {
        const component = this;
        const fr = new FileReader();
        fr.onload = function(e) {
          component.$emit("input", e.target.result);
        };
        fr.readAsDataURL(input.target.files[0]);
      }
    }
  }
};
</script>

<style>
.label {
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.holder {
  display: inline-block;
}
</style>

<template>
  <PageHolder breadCombsText="Home - Pedidos">
    <v-row>
      <v-col lg="3">
        <date-picker label="Data de Início" v-model="startDate" />
      </v-col>
      <v-col lg="3">
        <date-picker
          label="Data de Fim"
          v-model="endDate"
          :allowedDates="allowedEndDates"
        />
      </v-col>
      <v-col lg="6">
        <v-text-field
          label="Insira a Cidade"
          prepend-icon="mdi-map-marker-outline"
          v-model="city"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="6">
        <v-text-field
          label="Insira o Cliente"
          prepend-icon="mdi-account-outline"
          v-model="clientName"
        />
      </v-col>
      <v-col lg="6">
        <v-select
          :items="carriersList"
          item-text="nome"
          item-value="id"
          v-model="carrierId"
          label="Selecione a Transportadora"
          prepend-icon="mdi-store-outline"
          single-line
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="6">
        <v-select
          :items="statusList"
          label="Selecione o Status"
          prepend-icon="mdi-check"
          return-object
          single-line
          multiple
          v-model="status"
        />
      </v-col>
      <v-col lg="6" v-if="agentsList.length">
        <v-select
          :items="agentsList"
          item-text="usuario.nome"
          item-value="id"
          v-model="agentId"
          label="Selecione o Agente"
          prepend-icon="mdi-motorbike"
          single-line
        />
      </v-col>
    </v-row>
    <v-row>
      <div class="d-flex align-center">
        <h4>Pedidos em andamento</h4>
      </div>
      <v-spacer />
      <div class="d-flex align-center">
        <v-btn class="mr-4" @click="resetFilter()">
          Limpar filtros
        </v-btn>
      </div>

      <v-select
        multiple
        chips
        v-model="selectedHeaders"
        :items="selectableHeaders"
      />
    </v-row>
    <v-row>
      <v-layout child-flex>
        <v-data-table
          :headers="headers"
          :items="orders"
          :items-per-page="pageSize"
          :server-items-length="ordersCount"
          @update:page="requestItems"
          @update:options="updateTableOptions"
          class="elevation-1 mt-8"
          fill-width
          no-data-text="Sem dados disponíveis"
          no-results-text="Nenhuma entrada encontrada"
        >
          <template v-slot:[`item.details`]="{ item }">
            <v-icon @click="clickItemDetails(item)" class="ma-auto">
              mdi-dots-vertical-circle-outline
            </v-icon>
          </template>

          <template v-slot:[`item.transportadora.nome`]="{ item }">
            <v-btn
              v-if="!item.transportadora.nome"
              @click="clickInsertShipping(item)"
            >
              Inserir
            </v-btn>
            <td v-else>
              {{ item.transportadora.nome }}
              <v-icon v-if="!isCarrier" small @click="clickInsertShipping(item)"
                >mdi-pencil</v-icon
              >
            </td>
          </template>

          <template v-slot:[`item.agente.usuario.nome`]="{ item }">
            <v-btn
              v-if="!item.agente.usuario.nome"
              @click="clickInsertShipping(item)"
            >
              Inserir
            </v-btn>
            <td v-else>
              {{ item.agente.usuario.nome }}
              <v-icon small @click="clickInsertShipping(item)"
                >mdi-pencil</v-icon
              >
            </td>
          </template>

          <template v-slot:[`item.precoTransporte`]="{ item }">
            <td>
              {{ item.precoTransporte }}
              <v-icon v-if="!isCarrier" small @click="clickInsertShipping(item)"
                >mdi-pencil</v-icon
              >
            </td>
          </template>
        </v-data-table>
      </v-layout>
    </v-row>

    <OrderDetailsDialog
      :isDialogVisible="isOrderDetailsDialogVisible"
      :order="selectedOrder"
      @close-order-details-dialog="onCloseOrderDetailsDialog()"
    >
    </OrderDetailsDialog>
    <ShippingFormDialog
      :isDialogVisible="isShippingDialogVisible"
      :order="selectedOrder"
      @close-shipping-dialog="onCloseShippingDialog()"
      @save-shipping-update="onSaveShippingUpdate()"
    ></ShippingFormDialog>
  </PageHolder>
</template>

<script>
import { ordersRoutesPaths } from "../routes";
import { required } from "../../../utils/formFieldRules";
import { userTypes } from "@/modules/users/utils";

import OrderDetailsDialog from "../components/OrderDetailsDialog";
import ShippingFormDialog from "../components/ShippingFormDialog";

export default {
  components: {
    OrderDetailsDialog,
    ShippingFormDialog
  },

  data: () => ({
    isOrderDetailsDialogVisible: false,
    isShippingDialogVisible: false,
    selectedAgent: null,
    rules: { required },
    selectedOrder: {},
    startDate: "",
    endDate: "",
    agentId: "",
    carrierId: "",
    city: "",
    clientName: "",
    status: [
      { text: "Na Expedição", value: "NA_EXPEDICAO" },
      { text: "Coletado pela empresa", value: "COLETADO_PELA_EMPRESA" },
      { text: "No centro de distribuição", value: "NO_CENTRO_DISTRIBUICAO" },
      { text: "Em rota de entrega", value: "EM_ROTA_ENTREGA" }
    ],
    selectedHeaders: [
      "id",
      "nomeCliente",
      "transportadora.nome",
      "agente.usuario.nome",
      "precoTransporte",
      "nomeCidadeCliente",
      "ultimoStatus"
    ],
    statusList: [
      { text: "Na Expedição", value: "NA_EXPEDICAO" },
      { text: "Coletado pela empresa", value: "COLETADO_PELA_EMPRESA" },
      { text: "No centro de distribuição", value: "NO_CENTRO_DISTRIBUICAO" },
      { text: "Em rota de entrega", value: "EM_ROTA_ENTREGA" },
      { text: "Entregue", value: "ENTREGUE" },
      { text: "Entrega frustrada", value: "ENTREGA_FRUSTRADA" }
    ],
    pageSize: 10,

    refreshInterval: undefined
  }),

  created() {
    this.startDate = this.initialStartDate;
    this.endDate = this.initialEndDate;
  },

  mounted() {
    this.requestItems(1);
    this.$store.dispatch("requestCarriersList");
    this.refreshInterval = setInterval(() => {
      this.requestItems(1);
    }, 60000);
  },

  beforeDestroy() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = undefined;
    }
  },

  computed: {
    initialStartDate() {
      const firstDayOfMonth = new Date();
      firstDayOfMonth.setDate(1);
      return firstDayOfMonth.toISOString().slice(0, 10);
    },
    initialEndDate() {
      const lastDayOfMonth = new Date();
      lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1);
      lastDayOfMonth.setDate(0);
      return lastDayOfMonth.toISOString().slice(0, 10);
    },
    agentsList() {
      if (this.$store.state.ordersStore.agents) {
        return this.$store.state.ordersStore.agents;
      } else {
        return [];
      }
    },
    carriersList() {
      return this.$store.state.ordersStore.carriers;
    },
    orders() {
      return this.$store.state.ordersStore.orders;
    },
    ordersCount() {
      return this.$store.state.ordersStore.ordersCount;
    },
    headers() {
      return [
        ...this.selectableHeaders.filter(h =>
          this.selectedHeaders.includes(h.value)
        ),
        {
          text: "Detalhes",
          value: "details",
          sortable: false
        }
      ];
    },
    selectableHeaders() {
      let selectableHeaders = [
        { text: "ID", value: "id" },
        { text: "Cliente", value: "nomeCliente" },
        { text: "Entrada", value: "dtCadastro" },
        { text: "Expedição (Origem)", value: "expedicao.nome" },
        { text: "Transportadora", value: "transportadora.nome" },
        { text: "Agente", value: "agente.usuario.nome" },
        { text: "Valor do transporte", value: "precoTransporte" },
        { text: "Cidade destino", value: "nomeCidadeCliente" },
        { text: "Status", value: "ultimoStatus" },
        {
          text: "Bairro Destino",
          value: "bairroClienteEnd"
        }
      ];

      if (!this.isCarrier) {
        selectableHeaders.push({ text: "Preço", value: "valorTotal" });
      }
      return selectableHeaders;
    },
    filter() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
        agentId: this.agentId,
        carrierId: this.carrierId,
        city: this.city,
        clientName: this.clientName,
        status: this.status
      };
    },
    defaultFilter() {
      return {
        startDate: this.initialStartDate,
        endDate: this.initialEndDate,
        agentId: "",
        carrierId: "",
        city: "",
        clientName: "",
        status: [
          { text: "Na Expedição", value: "NA_EXPEDICAO" },
          { text: "Coletado pela empresa", value: "COLETADO_PELA_EMPRESA" },
          {
            text: "No centro de distribuição",
            value: "NO_CENTRO_DISTRIBUICAO"
          },
          { text: "Em rota de entrega", value: "EM_ROTA_ENTREGA" }
        ]
      };
    },
    isCarrier() {
      const userType = this.$store.state.authStore.userType;
      return userType === userTypes.carrier;
    }
  },

  watch: {
    agentsList(newValue) {
      if (!newValue) {
        this.agentId = "";
      }
    },
    startDate() {
      this.requestItems(1);
      this.setRefreshInterval();
    },
    endDate() {
      this.requestItems(1);
      this.setRefreshInterval();
    },
    agentId() {
      this.requestItems(1);
      this.setRefreshInterval();
    },
    carrierId(newValue) {
      if (newValue) {
        this.$store.dispatch("requestAgentsList", {
          carrierId: this.carrierId
        });
      }
      this.requestItems(1);
      this.setRefreshInterval();
    },
    city(newValue) {
      if (newValue.length > 2) {
        this.requestItems(1);
        this.setRefreshInterval();
      } else if (!newValue) {
        this.requestItems(1);
        this.setRefreshInterval();
      }
    },
    clientName(newValue) {
      if (newValue.length > 2) {
        this.requestItems(1);
        this.setRefreshInterval();
      } else if (!newValue) {
        this.requestItems(1);
        this.setRefreshInterval();
      }
    },
    status() {
      this.requestItems(1);
      this.setRefreshInterval();
    },
    ordersCount(newValue) {
      if (!newValue) {
        if (
          JSON.stringify(this.filter) !== JSON.stringify(this.defaultFilter)
        ) {
          this.resetFilter();
        }
      }
    }
  },

  methods: {
    async requestItems(page) {
      if (this.pageSize === -1) {
        this.$store.dispatch("requestOrders", {
          page: page - 1,
          pageSize: this.ordersCount,
          filter: this.filter
        });
      } else {
        this.$store.dispatch("requestOrders", {
          page: page - 1,
          pageSize: this.pageSize,
          filter: this.filter
        });
      }
    },
    navigateToCreate() {
      this.$router.push(ordersRoutesPaths.createOrder);
    },
    allowedEndDates(date) {
      const start = new Date(this.startDate.replaceAll("-", " "));
      const end = new Date(date.replaceAll("-", " "));
      return start < end;
    },
    clickInsertShipping(item) {
      this.selectedOrder = item;
      this.isShippingDialogVisible = true;
    },
    clickItemDetails(item) {
      this.selectedOrder = item;
      this.selectedOrder.nomeTransportadora = this.selectedOrder.transportadora.nome;
      this.selectedOrder.nomeAgente = this.selectedOrder.agente.usuario.nome;
      this.isOrderDetailsDialogVisible = true;
    },
    onCloseOrderDetailsDialog() {
      this.isOrderDetailsDialogVisible = false;
    },
    onCloseShippingDialog() {
      this.isShippingDialogVisible = false;
    },
    onSaveShippingUpdate() {
      this.isShippingDialogVisible = false;
      this.requestItems(1);
    },
    updateTableOptions(options) {
      this.pageSize = options.itemsPerPage;
      this.requestItems(options.page);
    },
    resetFilter() {
      this.selectedOrder = {};

      this.startDate = this.initialStartDate;
      this.endDate = this.initialEndDate;
      this.agentId = "";
      this.carrierId = "";
      this.city = "";
      this.clientName = "";
      this.status = [
        { text: "Na Expedição", value: "NA_EXPEDICAO" },
        { text: "Coletado pela empresa", value: "COLETADO_PELA_EMPRESA" },
        { text: "No centro de distribuição", value: "NO_CENTRO_DISTRIBUICAO" },
        { text: "Em rota de entrega", value: "EM_ROTA_ENTREGA" }
      ];
    },
    setRefreshInterval() {
      clearInterval(this.refreshInterval);
      this.refreshInterval = setInterval(() => {
        this.requestItems(1);
      }, 60000);
    }
  }
};
</script>

<style>
.v-select input {
  display: none;
}
.v-select {
  flex: unset;
}
</style>

import { getRequestIdAndIncrementCounter } from "../../service/service_store";
import { clearState } from "../../utils/vuex";

const initialState = {
  totalsByStatus: {},
  byLocationData: null,
  byCarriersData: null,
  statesData: null,
  deliveryValueByCarrier: [],
  deliveryValueByExpedition: [],
  salesmen: [],
  shippingFee: []
};

export const dashboardStore = {
  state: () => ({ ...initialState }),
  mutations: {
    setTotalsByStatus(state, totalsByStatus) {
      state.totalsByStatus = totalsByStatus;
    },
    setByLocationData(state, byLocationData) {
      state.byLocationData = byLocationData;
    },
    setByCarriersData(state, byCarriersData) {
      state.byCarriersData = byCarriersData;
    },
    setStatesData(state, statesData) {
      state.statesData = statesData;
    },
    setDeliveryValueByCarrier(state, deliveryValueByCarrier) {
      state.deliveryValueByCarrier = deliveryValueByCarrier;
    },
    setDeliveryValueByExpedition(state, deliveryValueByExpedition) {
      state.deliveryValueByExpedition = deliveryValueByExpedition;
    },
    setSalesmen(state, salesmen) {
      state.salesmen = salesmen;
    },
    setShippingFee(state, shippingFee) {
      state.shippingFee = shippingFee;
    },
    clearDashboardStore(state) {
      clearState(state, initialState);
    }
  },

  actions: {
    async requestTotalsByStatus(
      { dispatch, commit, getters },
      { startDate, endDate }
    ) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = `/dashboard/somatorio?dtinicio=${startDate}T00:00:00&dtfim=${endDate}T23:59:59`;
      await dispatch("requestWithAuth", { requestId, route });

      const request = getters.getRequests[requestId];

      if (request && request.ok) {
        commit("setTotalsByStatus", request.body);
      }

      commit("deleteRequest", requestId);
    },

    async requestByLocationData(
      { dispatch, commit, getters },
      { startDate, endDate }
    ) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = `/dashboard/agrupar/localizacao?dtinicio=${startDate}T00:00:00&dtfim=${endDate}T23:59:59`;
      await dispatch("requestWithAuth", { requestId, route });

      const request = getters.getRequests[requestId];

      if (request && request.ok) {
        commit("setByLocationData", request.body);
      }

      commit("deleteRequest", requestId);
    },

    async requestByCarriersData(
      { dispatch, commit, getters },
      { startDate, endDate }
    ) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = `/dashboard/agrupar/transportadora?dtinicio=${startDate}T00:00:00&dtfim=${endDate}T23:59:59`;
      await dispatch("requestWithAuth", { requestId, route });

      const request = getters.getRequests[requestId];

      if (request && request.ok) {
        commit("setByCarriersData", request.body);
      }

      commit("deleteRequest", requestId);
    },

    async requestStatesData(
      { dispatch, commit, getters },
      { startDate, endDate }
    ) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = `/dashboard/estado?dtinicio=${startDate}T00:00:00&dtfim=${endDate}T23:59:59`;
      await dispatch("requestWithAuth", { requestId, route });

      const request = getters.getRequests[requestId];

      if (request && request.ok) {
        commit("setStatesData", request.body);
      }

      commit("deleteRequest", requestId);
    },

    async requestDeliveryValueByCarrier(
      { dispatch, commit, getters },
      { startDate, endDate }
    ) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = `/dashboard/transportadora?dtinicio=${startDate}T00:00:00&dtfim=${endDate}T23:59:59`;
      await dispatch("requestWithAuth", { requestId, route });

      const request = getters.getRequests[requestId];

      if (request && request.ok) {
        for (let carrier of request.body) {
          carrier.valor = carrier.valor.toFixed(2);
        }

        commit("setDeliveryValueByCarrier", request.body);
      }

      commit("deleteRequest", requestId);
    },

    async requestDeliveryValueByExpedition(
      { dispatch, commit, getters },
      { startDate, endDate }
    ) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = `/dashboard/expedicao?dtinicio=${startDate}T00:00:00&dtfim=${endDate}T23:59:59`;
      await dispatch("requestWithAuth", { requestId, route });

      const request = getters.getRequests[requestId];

      if (request && request.ok) {
        for (let expedition of request.body) {
          expedition.valor = expedition.valor.toFixed(2);
        }
        commit("setDeliveryValueByExpedition", request.body);
      }

      commit("deleteRequest", requestId);
    },

    async requestSalesmen(
      { dispatch, commit, getters },
      { startDate, endDate }
    ) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = `/dashboard/vendedor?dtinicio=${startDate}T00:00:00&dtfim=${endDate}T23:59:59`;
      await dispatch("requestWithAuth", { requestId, route });

      const request = getters.getRequests[requestId];

      if (request && request.ok) {
        commit("setSalesmen", request.body);
      }

      commit("deleteRequest", requestId);
    },

    async requestShippingFee(
      { dispatch, commit, getters },
      { startDate, endDate }
    ) {
      const requestId = getRequestIdAndIncrementCounter({ commit, getters });

      const route = `/dashboard/pagamentotransportadora?dtinicio=${startDate}T00:00:00&dtfim=${endDate}T23:59:59`;
      await dispatch("requestWithAuth", { requestId, route });

      const request = getters.getRequests[requestId];

      if (request && request.ok) {
        request.body = request.body.filter(carrier => carrier.valor != 0);
        commit("setShippingFee", request.body);
      }

      commit("deleteRequest", requestId);
    }
  }
};
